import {camelCase} from 'lodash';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

import {hitPropType} from 'components/Search/utils';
import ResourceIcon from 'components/icons/Resource';
import useAppContext from 'conf/AppContext';
import {hasIcon} from 'conf/types';


const propTypes = {
  baseUrl: PropTypes.string.isRequired,
  /**
   * Words to highlight.
   */
  searchWords: PropTypes.arrayOf(PropTypes.string).isRequired,
  hit: hitPropType.isRequired,
  /**
   * True if this result should be highlighted (was selected via keyboard).
   */
  highlighted: PropTypes.bool,
};
/**
 * Renders an autocomplete result.
 */
export default function AutocompleteResult({baseUrl, searchWords, hit, highlighted = false}) {
  const appContext = useAppContext();
  const ccType = camelCase(hit.objCls);
  const icon = hasIcon(ccType);
  const highlightedClass = highlighted ? 'hover' : '';
  const url = baseUrl ? `${baseUrl},${hit.id}` : hit.url;


  /**
   * Catch mouse down event to resolve problem on Mac Safari/Firefox.
   */
  const onMouseDown = (event) => {
    if (appContext.isSafari) {
      // prevent default so that blur() isn't called (https://stackoverflow.com/a/57630197/1063501)
      event.preventDefault();
    }
  };

  return (
    <a
      className={`searchBar__autocomplete__result clickable hoverable ${highlightedClass}`}
      href={url}
      onMouseDown={onMouseDown}
    >
      <div className="searchBar__autocomplete__result__icon" role="presentation">
        {icon && <ResourceIcon type={ccType} /> }
      </div>
      <Highlighter
        className="searchBar__autocomplete__result__text"
        highlightClassName="highlight"
        searchWords={searchWords}
        highlightTag="span"
        textToHighlight={hit.name}
        autoEscape={true}
      />
    </a>
  );
}
AutocompleteResult.propTypes = propTypes;
