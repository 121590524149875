import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {isEmpty, isString} from 'lodash';
import PropTypes from 'prop-types';
import React, {createContext, ReactElement, useContext, useMemo, useState} from 'react';
import Modal from 'react-modal';

import Button from 'components/Button';
import ButtonPanel from 'components/Button/Panel';
import logger from 'conf/Logger';


const _baseModalOptions = {
  isOpen: false,
  content: undefined,
  closeHandler: undefined,
  closeIcon: false,
};

const _baseConfirmOptions = {
  isOpen: false,
  content: 'Are you sure?',
  yesLabel: 'Yes',
  noLabel: 'No',
  closeIcon: false,
};
const _baseConfirmStyle = {
  width: '20rem',
};


/**
 * Modal service context.  This supports rendering a modal.
 *
 * Modal can only be dismissed by:
 * * calling close()
 * * hitting ESC key
 */
const ModalServiceContext = createContext(undefined);


const propTypes = {
  children: PropTypes.node,
};
/**
 * This is a React.Context.Provider for the modal service.
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 */
export function ModalServiceProvider({children}) {
  const [modalConfig, setModalConfig] = useState(_baseModalOptions);


  /**
   * Renders modal.
   *
   * @param {object} options
   * @param {ReactElement} options.content
   * @param {object} [options.style]
   * @param {string} [options.style.width] - set width of modal, if modal should not determine width based on content
   * @param {string} [options.style.height] - set height of modal, if modal should not determine height based on content
   * @param {Function} [options.closeHandler] - function to call when modal is closed because ESC key is pressed
   */
  const open = (options) => {
    if (modalConfig.isOpen) {
      logger.error('Cannot only have one modal open at a time.  ' +
        'How did you manage to call this again anyway?');
      return;
    }
    // noinspection JSCheckFunctionSignatures
    setModalConfig({..._baseModalOptions, ...options, isOpen: true});
  };


  /**
   * Closes the modal.
   *
   * This ONLY closes the modal!  Id does NOT call closeHandler!
   */
  const close = () => {
    setModalConfig(_baseModalOptions);
  };


  /**
   * Renders confirm dialog.
   *
   * @param {object} options
   * @param {string|ReactElement} [options.content] - defaults to "Are you sure?"
   * @param {string|ReactElement} [options.yesLabel] - defaults to "Yes"
   * @param {string} [options.yesBtnClassName] - class to apply to yes button, optional
   * @param {string|ReactElement} [options.noLabel] - defaults to "No"
   * @param {string} [options.noBtnClassName] - class to apply to no button, optional, defaults btn-secondary
   * @param {Function} [options.yesHandler] - call function when "yes" is selected
   * @param {Function} [options.noHandler] - call function when "no" is selected
   * @param {object} [options.style]
   * @param {string} [options.style.width] - set width of modal
   * @param {string} [options.style.height] - set height of modal, if modal should not determine height based on content
   */
  const confirm = (options) => {
    const {style, ...nonStyleOpts} = options;
    const mergedOpts = {..._baseConfirmOptions, ...nonStyleOpts};
    const {content, yesLabel, yesBtnClassName, noLabel,
      noBtnClassName = 'btn-secondary', yesHandler, noHandler, ...otherOptions} = mergedOpts;

    const handleConfirmYes = () => {
      if (yesHandler) {
        yesHandler();
      }
      close();
    };

    const handleConfirmNo = () => {
      if (noHandler) {
        noHandler();
      }
      close();
    };

    const confirmContent = (
      <div>
        {isString(content) ? <p>{content}</p> : content}
        <ButtonPanel
          buttons={[
            <Button key="yes" actionHandler={handleConfirmYes} className={yesBtnClassName}>{yesLabel}</Button>,
            <Button key="no" actionHandler={handleConfirmNo} className={noBtnClassName}>{noLabel}</Button>,
          ]}
        />
      </div>
    );

    open({
      content: confirmContent,
      closeHandler: noHandler,
      ...otherOptions,
      style: isEmpty(style) ? _baseConfirmStyle : style,
    });
  };

  
  const closeRequestHandler = () => {
    if (modalConfig.closeHandler) {
      modalConfig.closeHandler();
    }
    close();
  };

  const providerProps = useMemo(() => ({
    open,
    close,
    confirm,
  }), [modalConfig.status]);

  return (
    <>
      <ModalServiceContext.Provider value={providerProps}>
        {children}
      </ModalServiceContext.Provider>
      <Modal
        isOpen={modalConfig.isOpen}
        onRequestClose={closeRequestHandler}
        className="modalWindow"
        overlayClassName="modalOverlay"
        style={{content: modalConfig.style}}
        shouldCloseOnOverlayClick={false}
        // disable page scrolling under modal (https://github.com/reactjs/react-modal/issues/191)
        // TODO(markwoon): doesn't work in all browsers
        // TODO(markwoon): consider moving to https://github.com/willmcpo/body-scroll-lock
        onAfterOpen={() => {
          document.body.style.overflow = 'hidden';
        }}
        onAfterClose={() => {
          document.body.removeAttribute('style');
        }}
      >
        {modalConfig.closeIcon && (
          <div className="clearfix">
            <span className="modalWindow__close">
              <button onClick={close} className="btn">
                <FontAwesomeIcon icon="times" size="lg" />
                <span className="screen-reader-text">close</span>
              </button>
            </span>
          </div>
        )}
        <div>
          {isString(modalConfig.content) ? <p>{modalConfig.content}</p> : modalConfig.content}
        </div>
      </Modal>
    </>
  );
}
ModalServiceProvider.propTypes = propTypes;


const useModalService = () => {
  const context = useContext(ModalServiceContext);
  if (!context) {
    throw new Error('ModalServiceContext must be used within ModalServiceProvider');
  }
  return context;
};
export default useModalService;
