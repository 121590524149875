import {forEach, map, reduce} from 'lodash';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import KyError, {hasJsendFieldErrors} from 'components/errors/KyError';
import useAppContext from 'conf/AppContext';

const propTypes = {
  /** RHF form mode. */
  mode: PropTypes.string,
  defaultValues: PropTypes.object,
  resolver: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
};
/**
 * Form wrapper to use with react-hook-form.
 *
 * @deprecated use FormProvider and <form> element instead
 */
export default function Form({mode = 'onSubmit', defaultValues = {}, resolver, onSubmit, children}) {
  const appContext = useAppContext();
  const formMethods = useForm({mode, defaultValues, resolver});
  const {handleSubmit, setError} = formMethods;
  const [kyError, setKyError] = useState(null);

  // this function wraps onSubmit to handle field-level error assignments from JSend API responses
  const wrapSubmit = async (data) => {
    try {
      setKyError('');
      await onSubmit(data);
    } catch (err) {
      if (appContext.api.isBadRequest(err)) {
        const errorResponse = await appContext.api.readResponse(err.response);
        if (hasJsendFieldErrors(errorResponse)) {
          forEach(Object.entries(errorResponse?.data), ([key, value]) => {
            const message = reduce(map(value, (e) => e.message), (r, m) => r + '; ' + m);
            setError(key, {type: 'api', message});
          });
          return;
        }
      }
      setKyError(<KyError kyError={err} />);
    }
  };

  return (
    <FormProvider {...formMethods}>
      {kyError}
      <form onSubmit={handleSubmit(wrapSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}
Form.propTypes = propTypes;
