import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import DeleteButton from 'components/Button/Delete';
import ApproveControl from 'components/edit/ApproveControl';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import useAppContext from 'conf/AppContext';

/**
 * This component will show either an "add" button or an "edit" button for a data annotation
 *
 * @param {object} props - the props
 * @param {string} props.type - the type of data annotation this is editing
 * @param {string} props.targetId - the ID of the object to be annotated
 * @param {number} props.annotationId - the optional ID of the annotation, if it already exists
 * @param {boolean} props.allowDelete - show a delete button be shown, default false
 * @return {*} an IconButton
 * @class
 */
const DataAnnotationUpsertButton = ({type, targetId, annotationId, allowDelete = false}) => {
  const appContext = useAppContext();
  const [disabled, setDisabled] = useState(false);

  const reloadPage = () => window.location.reload();
  const handleDeleteFailure = (id, err) => {
    appContext.toastError(<KyError kyError={err} />);
  };
  const handleDeleteSuccess = (id) => {
    setDisabled(true);
    const deleteMessage = (
      <span>
        Deleted {id}, reload to see changes <Button className="btn-sm btn-outline-primary" actionHandler={reloadPage}>Reload</Button>
      </span>
    );
    toast.success(deleteMessage, {autoClose: false});
  };
  

  if (!annotationId) {
    return (
      <EditControls>
        <Button href={`/edit/dataAnnotation?type=${type}&target=${targetId}`}>
          <FontAwesomeIcon icon="plus" /> Add
        </Button>
      </EditControls>
    );
  } else {
    return (
      <EditControls>
        <Button href={`/edit/dataAnnotation/${annotationId}`} disabled={disabled}>
          <FontAwesomeIcon icon="edit" /> Edit
        </Button>
        <ApproveControl type="DataAnnotation" id={annotationId} disabled={disabled} />
        {allowDelete && (
          <DeleteButton
            url={`curation/dataAnnotation/${annotationId}`}
            id={annotationId}
            onSuccess={handleDeleteSuccess}
            onFailure={handleDeleteFailure}
            disabled={disabled}
          />
        )}
      </EditControls>
    );
  }
};
DataAnnotationUpsertButton.propTypes = {
  type: PropTypes.string.isRequired,
  targetId: PropTypes.string.isRequired,
  annotationId: PropTypes.number,
};
export default DataAnnotationUpsertButton;
