import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {capitalize, map} from 'lodash';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import ApproveButton from 'components/Button/Approve';
import WaitingButton from 'components/Button/Waiting';
import DataAnnotationUpsertButton from 'components/DataAnnotation/UpsertButton';
import MultiColumnList from 'components/List/MultiColumn';
import Loading from 'components/Loading';
import PharmgkbTagButton from 'components/PharmgkbTag/Button';
import Tag from 'components/Tag';
import PediatricTag from 'components/Tag/Pediatric';
import useEditContext from 'components/edit/EditContext';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import ResourceCounts, {convertTypedCounts, resourceCountsProps} from 'components/resource/resourceCounts';
import useModalService from 'components/shared/ModalService';
import CuratorOnly from 'components/shared/curator_only';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import VipSummary from 'components/vip_gene/VipSummary';
import useAppContext from 'conf/AppContext';
import {DataSource} from 'conf/enums';
import {accessionIdProps, ontologyTermProps} from 'conf/types';
import usePediatricContext from 'context/Pediatric';


const propTypes = {
  gene: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    chr: accessionIdProps,
    cbStart: PropTypes.string,
    cbStop: PropTypes.string,
    chrStartPosB37: PropTypes.number,
    chrStopPosB37: PropTypes.number,
    chrStartPosB38: PropTypes.number,
    chrStopPosB38: PropTypes.number,
    strand: PropTypes.string,
    altNames: PropTypes.shape({
      synonym: PropTypes.arrayOf(PropTypes.string),
      symbol: PropTypes.arrayOf(PropTypes.string),
    }),
    cpicGene: PropTypes.bool,
    pharmVarGene: PropTypes.bool,
    terms: PropTypes.arrayOf(ontologyTermProps),
    amp: PropTypes.bool,
  }),
  counts: resourceCountsProps,
  needsApproval: PropTypes.bool,
  isPediatric: PropTypes.bool,
  vip: PropTypes.shape({
    vipId: PropTypes.string.isRequired,
    vipSummary: PropTypes.string.isRequired,
    vipTier: PropTypes.string.isRequired,
  }),
  description: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
  }),
  pedSummary: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
  }),
  acmgIncFind: PropTypes.shape({
    id: PropTypes.number.isRequired,
    html: PropTypes.string,
  }),
  frequencySources: PropTypes.arrayOf(PropTypes.string),
};

export default function GeneOverviewTab({gene, counts, needsApproval, isPediatric, vip, description, pedSummary,
  acmgIncFind, frequencySources}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const editContext = useEditContext();
  const modalService = useModalService();
  const [geneSync, setGeneSync] = useState(false);

  if (!gene?.id) {
    return <Loading />;
  }

  const updateGeneBorders = () => {
    const yesHandler = async () => {
      try {
        setGeneSync(true);
        await appContext.api.get(`curation/gene/${gene.id}/_sync`);
        appContext.reload();
        toast.success(`${gene.symbol} updated`);
      } catch (error) {
        appContext.toastError(<KyError kyError={error} />);
      } finally {
        setGeneSync(false);
      }
    };
    modalService.confirm({
      yesHandler,
      content: 'This will fetch gene information from NCBI and update this gene.  Update?',
      yesLabel: 'Update',
      noLabel: 'Cancel',
    });
  };


  const resourceCounts = gene?.id ? convertTypedCounts('gene', gene?.id, counts) : [];
  const chr = gene?.chr?.name ? `${gene.chr.name} : ` : '';
  let cytoBoundaries = '';
  if (gene.cbStart) {
    if (gene.cbStop) {
      cytoBoundaries = `${chr}${gene.cbStart} - ${gene.cbStop}`;
    } else {
      cytoBoundaries = `${chr}${gene.cbStart}`;
    }
  }
  const hg37Boundaries = gene.chrStopPosB37 > 0 ? `${chr}${gene.chrStartPosB37} - ${gene.chrStopPosB37}` : '';
  const hg38Boundaries = gene.chrStopPosB38 > 0 ? `${chr}${gene.chrStartPosB38} - ${gene.chrStopPosB38}` : '';
  const altNames = gene?.altNames?.synonym ? gene.altNames.synonym : '';
  const altSymbols = gene?.altNames?.symbol ? gene.altNames.symbol : '';
  const onApproveSuccess = () => {
    toast.success('Approved.');
    appContext.reloadData();
  };

  return (
    <>
      <EditControls>
        <WaitingButton
          type="button"
          actionHandler={updateGeneBorders}
          waiting={geneSync}
          waitingLabel="Syncing…"
        >
          <FontAwesomeIcon icon="sync" className="mr-2" />
          NCBI Sync
        </WaitingButton>
        <ApproveButton objCls="gene" objId={gene.id} disabled={!needsApproval} onSuccess={onApproveSuccess} />
        <PharmgkbTagButton objId={gene.id} objCls="gene" />
      </EditControls>

      <div>
        <FactSection>
          <ResourceCounts counts={resourceCounts} />
          {(gene.cpicGene || gene.pharmVarGene || gene.amp || isPediatric) && (
            <div className="mt-3">
              {gene.cpicGene && <Tag>CPIC Gene</Tag>}
              {gene.pharmVarGene && <Tag>PharmVar Gene</Tag>}
              {gene.amp && <Tag tooltipUrl="/page/ampAllelesToTest" tooltipText="Learn more about AMP">AMP Gene</Tag>}
              {isPediatric && <PediatricTag />}
            </div>
          )}
        </FactSection>

        {pediatricContext.isPediatricMode && (pedSummary || editContext.isEditMode) && (
          <FactSection sectionClasses="alert alert-info">
            <Fact
              label="Pediatric Summary"
              html={pedSummary?.html}
              hideWhenEmpty={!editContext.isEditMode}
              labelBtns={<DataAnnotationUpsertButton targetId={gene.id} type="pedSummary" annotationId={pedSummary?.id} />}
            />
          </FactSection>
        )}

        <FactSection>
          <Fact
            label="Description"
            html={description?.html}
            hideWhenEmpty={!appContext.isPreview}
            labelBtns={<DataAnnotationUpsertButton targetId={gene.id} type="Description" annotationId={description?.id} />}
          />

          <VipSummary id={vip?.vipId} tier={vip?.vipTier} summary={vip?.vipSummary} geneId={gene.id} />

          <Fact
            label="ACMG Incidental Finding"
            html={acmgIncFind?.html}
            hideWhenEmpty={!appContext.isPreview}
            labelBtns={<DataAnnotationUpsertButton targetId={gene.id} type="ACMG Incidental Findings" annotationId={acmgIncFind?.id} />}
          />
        </FactSection>

        <FactSection title="Location">
          <table className="table table-sm">
            <tbody>
              <tr>
                <td width={150}><b>Strand</b></td>
                <td>{capitalize(gene.strand)}</td>
              </tr>
              <tr>
                <td><b>Cytogenetic</b></td>
                <td>{cytoBoundaries}</td>
              </tr>
              <tr>
                <td><b>GRCh37</b></td>
                <td>{hg37Boundaries}</td>
              </tr>
              <tr>
                <td><b>GRCh38</b></td>
                <td>{hg38Boundaries}</td>
              </tr>
            </tbody>
          </table>
        </FactSection>

        <CuratorOnly>
          <FactSection title="Frequencies">
            <ul className="list-unstyled">
              <li>
                <Button newTab={false} href={appContext.apiUrl(`/site/haplotypeFrequency/_download/${gene.id}`)} className="btn-secondary">
                  <FontAwesomeIcon icon={['fad', 'download']} /> Download all frequencies
                </Button>
              </li>
              {map(frequencySources, (s) => (
                <li key={s}>
                  <Button newTab={false} href={appContext.apiUrl(`/site/haplotypeFrequency/_download/${gene.id}?source=${DataSource.displayName[s]}`)} className="btn-outline-secondary">
                    <FontAwesomeIcon icon={['fad', 'download']} /> Download only {DataSource.displayName[s]} frequencies
                  </Button>
                </li>
              ))}
            </ul>
          </FactSection>
        </CuratorOnly>

        <FactSection title="Names & Symbols">
          <EditControls>
            <Button href={`/edit/alternateNames/gene/${gene.id}`}>
              <FontAwesomeIcon icon="edit" /> Edit alternate names/symbols
            </Button>
          </EditControls>
          <Fact label="PharmGKB ID" literal={gene.id} />
          <Fact label="HGNC Approved Name" literal={gene.name} />
          <Fact label="Alternate Names">
            {
              altNames.length === 0 ? <p className="text-muted">None</p>
                : <MultiColumnList data={altNames} />
            }
          </Fact>
          <Fact label="Alternate Symbols">
            {
              altSymbols.length === 0 ? <p className="text-muted">None</p>
                : <MultiColumnList data={altSymbols} />
            }
          </Fact>
        </FactSection>
      </div>
    </>
  );
}
GeneOverviewTab.propTypes = propTypes;
