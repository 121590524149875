import PropTypes from 'prop-types';
import {filter, map, sortBy, uniq} from 'lodash';

import LiteratureCitation from 'components/Literature/Citation';
import {renderAccIdLink, renderLinks} from 'components/shared/utils';
import FactSection from 'components/shared/fact_section';
import Fact from 'components/shared/fact';

const propTypes = {
  relationships: PropTypes.arrayOf(PropTypes.object),
};

export default function RelationshipsTab({relationships = []}) {

  let data;
  if (relationships.length === 0) {
    data = <p className="empty">None.</p>;
  } else {
    const sortedRels = sortBy(relationships, (r) => {
      const obj = r.object2s[0];
      const name = obj.symbol || obj.name;
      return name.toLowerCase();
    });

    const types = sortBy(uniq(map(relationships, (r) => r.object2s[0].objCls)));

    data = (
      <>
        <FactSection>
          {renderRelationships(types, sortedRels)}
        </FactSection>
      </>
    );
  }

  return (
    <section>
      <h3>Relationships</h3>
      {data}
    </section>
  );
}
RelationshipsTab.propTypes = propTypes;


function renderRelationships(types, relationships) {
  return map(types, (t) => {
    const showRels = filter(relationships, (r) => r.object2s[0].objCls === t);
    return (
      <Fact label={t} key={t}>
        <ul>{map(showRels, renderRel)}</ul>
      </Fact>
    );
  });
}

function renderRel(rel) {
  const obj1s = map(rel.object1s, renderAccIdLink);
  const obj2s = map(rel.object2s, renderAccIdLink);
  let term = 'related to';
  if (rel.terms && rel.terms.length > 0) {
    term = rel.terms[0].term.toLowerCase();
    if (obj1s.length > 1) {
      term += 's';
    } else {
      const c = term.charAt(0);
      if (/[aeiou]/.test(c)) {
        term = 'an ' + term;
      } else {
        term = 'a ' + term;
      }
    }
    term += ' of ';
  }
  let pmids = '';
  if (rel.literature && rel.literature.length > 0) {
    pmids = (<p className="ml-4 mb-0"><small>{map(rel.literature, (l) => (<LiteratureCitation {...l} />))}</small></p>);
  }

  return (
    <li className="mb-3">
      {renderLinks(obj2s, rel.operator2)}
      {obj2s.length === 1 ? ` is ${term} ` : ` are ${term} `}
      {renderLinks(obj1s, rel.operator1)}
      {pmids}
    </li>
  );
}
