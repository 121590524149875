import clsx from 'clsx';
import PropTypes from 'prop-types';

import './LabelAnnotationLevel.scss';
import Tag from 'components/Tag/index';
import {getLevelId, getLevelName} from 'utils/pgxLevelUtils';


const propTypes = {
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showHelp: PropTypes.bool,
  className: PropTypes.string,
};
export default function LabelAnnotationLevelTag({level, showHelp = true, className}) {
  if (!level) {
    return null;
  }
  const infoProps = {};
  if (showHelp) {
    infoProps.tooltipText = 'Learn more about Label Annotation Levels of Evidence';
    infoProps.tooltipUrl = '/page/drugLabelLegend';
  }
  const lvlId = getLevelId(level);
  const lvlName = getLevelName(lvlId);

  const classes = clsx(`laLevelTag laLevelTag--${lvlId}`, className);
  return (level === 'No label found'
    ? (
      <div className={classes}>
        <span>{level}</span>
      </div>
    ) : (
      <Tag className={classes} {...infoProps}>
        {lvlName}
      </Tag>
    ));
}
LabelAnnotationLevelTag.propTypes = propTypes;
