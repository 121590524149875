import clsx from 'clsx';
import {forEach, isFunction} from 'lodash';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

import './Select.scss';


const propTypes = {
  /**
   * Column props passed by react-table
   */
  column: PropTypes.shape({
    /**
     * Controlled value of the input field
     */
    filterValue: PropTypes.string,
    /**
     * Function used to control filterValue
     */
    setFilter: PropTypes.func,
    /**
     * Array of rows used right before filtering
     */
    preFilteredRows: PropTypes.arrayOf(PropTypes.object),
    /**
     * Resulting array of rows received from this column's filter after filtering took place.
     */
    filteredRows: PropTypes.arrayOf(PropTypes.object),
    /**
     * Unique id of column
     */
    id: PropTypes.string,
    /**
     * Column header.
     */
    Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    /**
     * CSS classes to be added to the filter cell.
     */
    filterClassName: PropTypes.string,
  }).isRequired,
  /**
   * Label - used for screen reader-only label.
   * If unspecified, screen reader-only label defaults to `Header` value.
   */
  label: PropTypes.string,
};

/**
 * This renders a select input with all options for that column.
 * Useful for filtering a column with an enumerated type
 *
 * @return {JSX.Element}
 */
export default function SelectFilter({
  column: {filterValue = '', preFilteredRows, filteredRows, setFilter, id, Header, filterClassName},
  label,
}) {
  // Get all the available options for the column
  const options = useMemo(() => {
    const uniqueOptions = new Set();
    forEach(preFilteredRows, (row) => {
      uniqueOptions.add(row.values[id]);
    });
    return Array.from(uniqueOptions).sort();
  }, [id, preFilteredRows]);

  // Get options to show, so we can disable options that wouldn't show any rows
  const enabledOptions = useMemo(() => {
    const uniqueOptions = new Set();
    forEach(filteredRows, (row) => {
      uniqueOptions.add(row.values[id]);
    });
    return Array.from(uniqueOptions);
  }, [filteredRows]);

  let ariaLabel;
  if (label) {
    ariaLabel = `Filter ${label}`;
  } else if (isFunction(Header)) {
    ariaLabel = `Filter ${Header()}`;
  } else {
    ariaLabel = `Filter ${Header}`;
  }

  return (
    <label className={clsx('selectFilter', filterClassName)}>
      <select
        name={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        className={clsx('custom-select w-100', filterValue && 'filterSet')}
      >
        <option value="">All</option>
        {options.map((option) => (
          <option key={option} value={option} disabled={!filterValue && !enabledOptions.includes(option)}>
            {option}
          </option>
        ))}
      </select>
      <span className="sr-only">{ariaLabel}</span>
    </label>
  );
}

SelectFilter.propTypes = propTypes;
