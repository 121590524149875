import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {find, indexOf, map} from 'lodash';
import {useState} from 'react';

import HaplotypeFrequencyTable from 'components/HaplotypeFrequencyTable';
import FactSection from 'components/shared/fact_section';

import './FrequencySection.scss';


const tabLabels = {
  AOU: 'All of Us',
  UKBB: 'UK Biobank',
  CPIC: 'CPIC',
};

export function HaplotypeFrequencySection({frequency, alleleName}) {
  const [freqSource, setFreqSource] = useState(frequency[0].typeKey);
  const data = find(frequency, (f) => f.typeKey === freqSource);
  return (
    <FactSection title="Frequencies">
      <nav className="nav nav-tabs" role="tablist">
        {map(frequency, (t) => {
          const active = t.typeKey === freqSource;
          const hasData = Object.prototype.hasOwnProperty.call(t, 'populations');
          const dataIcon = hasData ? <span className="hapFreqNav__dot"><FontAwesomeIcon icon="circle" title="Has data available" /></span> : null;
          return (
            <a
              key={t.type}
              href="#"
              role="tab"
              aria-selected={active}
              className={clsx('nav-item', 'nav-link', {active}, {'text-muted': !hasData})}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setFreqSource(t.typeKey);
              }}
            >
              {t.label} {dataIcon}
            </a>
          );
        })}
      </nav>
      <HaplotypeFrequencyTable alleleName={alleleName} data={data} source={freqSource} />
    </FactSection>
  );
}

// frequencies should show in this preferred order, set by curators
const frequencySourceOrder = Object.keys(tabLabels);
// we want to order by the above source list BUT list frequencies with data first
export function compareFrequency(a, b) {
  if (!a) return 1;
  if (!b) return -1;

  const aHasData = Object.prototype.hasOwnProperty.call(a, 'populations');
  const bHasData = Object.prototype.hasOwnProperty.call(b, 'populations');

  // sort things with data first
  if (aHasData && !bHasData) return -1;
  if (!aHasData && bHasData) return 1;

  return indexOf(frequencySourceOrder, a.typeKey) - indexOf(frequencySourceOrder, b.typeKey);
}

export function sortAndLabelFrequencies(frequencyObj = {}) {
  return map(Object.keys(frequencyObj), (key) => ({
    ...frequencyObj[key],
    typeKey: key,
    label: tabLabels[key],
  })).sort(compareFrequency);
}
