import {map, size, toNumber} from 'lodash';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {toast} from 'react-toastify';

import DeleteButton from 'components/Button/Delete';
import EditButton from 'components/Button/Edit';
import List from 'components/List';
import {renderItemWithTags} from 'components/List/Item';
import Loading from 'components/Loading';
import ApproveControl from 'components/edit/ApproveControl';
import EditControls from 'components/edit/EditControls';
import KyError from 'components/errors/KyError';
import FactSection from 'components/shared/fact_section';
import {addHtmlContainerWhenLoaded} from 'components/shared/html_container';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import {findType} from 'conf/types';
import {useGet} from 'helpers/KyHooks';
import {englishJoin} from 'helpers/util';


const propTypes = {
  id: PropTypes.string,
};
/**
 * The page for Data Annotations
 *
 * @param {object} props
 * @param {string} [props.id] - A data annotation id
 * @return {React.ReactNode}
 */
export default function DataAnnotationPage({id}) {
  const appContext = useAppContext();
  const {response, error} = useGet(`data/dataAnnotation/${id}`, {searchParams: {view: 'most'}});
  const [disabled, setDisabled] = useState(false);

  let body;
  let title;

  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {

    const {targets, literature, markdown, type} = response.data;

    const getName = () => {
      if (targets.length > 0) {
        const targetString = englishJoin(targets.map((t) => t.symbol || t.name), 'and');
        return `${type} for ${targetString}`;
      }
      return type;
    };

    title = getName();

    const list =
      size(literature) > 0
        ? <List collection={literature} renderItemFn={renderItemWithTags} singularNoun="literature" pluralNoun="literature" />
        : null;

    // If the data annotation is not a publication set, wrap the list of publications in a
    // FactSection for a title
    const publications = type !== 'Publication Set' && list
      ? <FactSection title={`Supporting Literature (${literature.length})`}>{list}</FactSection>
      : list;

    const deleteSuccessHandler = () => {
      setDisabled(true);
      toast.warn('Annotation deleted', {autoClose: false});
    };

    const deleteFailureHandler = (daId, err) => {
      appContext.toastError(<KyError kyError={err} />);
    };
    body = (
      <div>
        <EditControls>
          <EditButton href={`/edit/dataAnnotation/${id}`} disabled={disabled}>Edit</EditButton>
          <ApproveControl type="dataAnnotation" id={toNumber(id)} disabled={disabled} />
          <DeleteButton
            url={`curation/dataAnnotation/${id}`}
            id={id}
            onSuccess={deleteSuccessHandler}
            onFailure={deleteFailureHandler}
            disabled={disabled}
          />
        </EditControls>

        <FactSection>
          {addHtmlContainerWhenLoaded(markdown.html)}
        </FactSection>
        <FactSection title="Related">
          {targets ? (
            <ul>
              {map(targets, (t, key) => (
                <li key={`${key}${t.url}`}>
                  <a href={findType(t.objCls)?.url(t.id)}>{t.symbol || t.name}</a>
                </li>
              ))}
            </ul>
          ) : <p className="muted">None found</p>}
        </FactSection>
        {publications}
      </div>
    );
  } else {
    body = <Loading />;
  }

  return (
    <ReadableWidthTemplate title={title || ''}>
      {body}
    </ReadableWidthTemplate>
  );
}

DataAnnotationPage.protoTypes = propTypes;
