import {map} from 'lodash';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {toast} from 'react-toastify';

import ButtonPanel from 'components/Button/Panel';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import Form from 'components/form';
import FormCancel from 'components/form/Cancel';
import FormCheckboxGroup from 'components/form/CheckboxGroup';
import FormSubmit from 'components/form/Submit';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';


const propTypes = {
  objId: PropTypes.string,
  objCls: PropTypes.string,
  closeHandler: PropTypes.func.isRequired,
};
/**
 * Renders modal form for selecting PharmGKB Tags to apply to an entity.
 */
export default function PharmgkbTagForm({objCls, objId, closeHandler}) {
  const appContext = useAppContext();
  const [submitError, setSubmitError] = useState(null);

  const {response, error} = useGet(`curation/tag/${objCls}/${objId}`, {
    cacheResponse: false,
  }, {
    transform: (r) => ({
      tags: makeOntologyTermOptions(r.data.tags),
      selected: r.data.selected,
    }),
  });

  const closeForm = () => {
    closeHandler();
  };


  const onSubmit = async (values) => {
    if (submitError) {
      setSubmitError(undefined);
    }
    try {
      await appContext.api.post(`curation/tag/${objCls}/${objId}`, {
        json: values,
        parseJson: true,
      });
      toast.success('Saved!');
      closeForm();
      appContext.reloadData();

    } catch (submitErr) {
      setSubmitError(<KyError kyError={submitErr} />);
    }
  };

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const official = [];
    const notOfficial = [];
    for (let x = 0; x < response.tags.length; x += 1) {
      const t = response.tags[x];
      if (t.forType.length > 0 && !t.forType.includes(objCls.toLowerCase())) {
        continue;
      }
      if (t.official) {
        official.push(t);
      } else {
        notOfficial.push(t);
      }
    }
    const officialTags = official.length > 0
      ? <FormCheckboxGroup name="tags" label={(<h4>Official Tags</h4>)} options={official} className="mb-4" />
      : null;

    body = (
      <>
        {submitError}
        <Form key="pgkbTagForm" defaultValues={{tags: response.selected}} onSubmit={onSubmit}>
          {officialTags}
          <FormCheckboxGroup
            name="tags"
            label={(<h4>PharmGKB Tags</h4>)}
            options={notOfficial}
          />

          <ButtonPanel
            buttons={[
              <FormSubmit>Update</FormSubmit>,
              <FormCancel actionHandler={closeForm}>Cancel</FormCancel>,
            ]}
          />
        </Form>
      </>
    );
  }

  return body;
}
PharmgkbTagForm.propTypes = propTypes;

const officialRegex = /\[official]/i;
const forTypeRegex = /\[forType:(.*?)]/;

function mapOntologyTerm(ot) {
  const typeMatch = forTypeRegex.exec(ot.description);
  const forType = [];
  if (typeMatch) {
    const types = typeMatch[1].split(',');
    for (let x = 0; x < types.length; x += 1) {
      forType.push(types[x].trim().toLowerCase());
    }
  }
  return {
    label: ot.term,
    value: ot.termId,
    official: officialRegex.test(ot.description),
    forType,
  };
}

/**
 * Make options for use in a form component from terms
 *
 * @param {Array<object>} terms
 * @return {{label: *, value: *}[]|undefined}
 */
function makeOntologyTermOptions(terms) {
  if (!terms) {
    return undefined;
  }
  return map(terms, mapOntologyTerm);
}
