import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {find} from 'lodash';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import License from 'components/License';
import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import KyError from 'components/errors/KyError';
import NewTabIcon from 'components/icons/NewTab';
import DownloadLink from 'components/links/Download';
import FileDownloadLink from 'components/links/Download/File';
import InfoLink from 'components/links/Info';
import Link from 'components/links/Link';
import MailtoLink from 'components/links/Mailto';
import CuratorOnly from 'components/shared/curator_only';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';
import {eraseCookie, readCookie} from 'helpers/cookies';
import DownloadCard from 'pages/Downloads/Card';


export default function DownloadsPage() {
  const appContext = useAppContext();

  const {response, error} = useGet('data/file/data/', {
    searchParams: {
      view: 'min',
    },
    cacheResponse: !appContext.isPreview,
  });

  const dataUrl = (type, id) => appContext.apiUrl(`/download/${type}/${id}`);

  let content = <Loading />;
  if (error) {
    content = <KyError kyError={error} />;
  } else if (response) {
    const files = response.data;
    const getFile = (filename) => find(files, (f) => f.fileName === filename);
    content = (
      <>
        <div>
          <h2>From PharmGKB</h2>
          <InlinePage id="downloadsHeader" editHint="Overview" />
          <License className="mt-3" />

          <section>
            <div className="fact-section">
              <h3>Annotations Data</h3>
              <p>Downloads contain information from PharmGKB annotations.</p>
            </div>

            <div className="row">
              <DownloadCard title="Variant and Clinical Annotations Data" svgIcon="clinicalAnnotation">
                <p>Clinical annotation summaries.</p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('clinicalAnnotations.zip')} />
                    {' '}<InfoLink href="/page/downloadClinicalAnnotationsHelp" tooltip="About Annotations archive" />
                  </li>
                  <li>
                    <FileDownloadLink file={getFile('clinicalAnnotations_LOE1-2.zip')} />
                    {' '}<InfoLink href="/page/downloadClinicalAnnotationsHelp" tooltip="About Annotations archive" />
                  </li>
                </ul>
                <p>Variant annotation summary.</p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('variantAnnotations.zip')} />
                    {' '}<InfoLink href="/page/downloadVariantAnnotationsHelp" tooltip="About Annotations archive" />
                  </li>
                </ul>
              </DownloadCard>

              <DownloadCard title="Variant, Gene and Drug Relationship Data" svgIcon="connection">
                <p>Relationships summarized from PharmGKB annotations.</p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('relationships.zip')} />
                    {' '}<InfoLink href="/page/downloadRelationshipsHelp" tooltip="About Relationships archive" />
                  </li>
                </ul>
              </DownloadCard>
            </div>

            <div className="row">
              <DownloadCard title="Clinical Guideline Annotations" svgIcon="guidelineAnnotation">
                <p>Detailed clinical guideline annotations in <Link href="http://www.json.org">JSON</Link> format:</p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('guidelineAnnotations.json.zip')} />
                    {' '}<InfoLink href="/page/downloadGuidelineJsonHelp" tooltip="About Clinical Guideline Annotations archive" />
                  </li>
                </ul>
              </DownloadCard>

              <DownloadCard title="Drug Label Annotations" svgIcon="labelAnnotation">
                <p>Drug label annotations in <Link href="http://en.wikipedia.org/wiki/Tab-separated_values">TSV</Link> format:</p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('drugLabels.zip')} />
                    {' '}<InfoLink href="/page/downloadLabelHelp" tooltip="About Drug Label Annotations archive" />
                  </li>
                </ul>
              </DownloadCard>
            </div>

            <div className="row">
              <DownloadCard title="Pathways" svgIcon="pathway">
                <p>
                  Pathways data in <Link href="http://biopax.org">BioPax XML</Link> and <Link href="http://en.wikipedia.org/wiki/Tab-separated_values">TSV</Link> formats:
                </p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('pathways-biopax.zip')} />
                    {' '}<InfoLink href="/page/downloadPathwayBiopaxHelp" tooltip="About Pathway Biopax archive" />
                  </li>
                  <li>
                    <FileDownloadLink file={getFile('pathways-tsv.zip')} />
                    {' '}<InfoLink href="/page/downloadPathwayTsvHelp" tooltip="About Pathway TSV archive" />
                  </li>
                </ul>
              </DownloadCard>

              <DownloadCard title="Clinical Variant Data" svgIcon="variant">
                <p>
                  This file contains a list of variant-drug pairs and level of evidence for all
                  clinical annotations in <Link href="http://en.wikipedia.org/wiki/Tab-separated_values">TSV</Link> format:
                </p>

                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('clinicalVariants.zip')} />
                    {' '}<InfoLink href="/page/downloadClinicalVariantHelp" tooltip="About Clinical Variant archive" />
                  </li>
                </ul>
              </DownloadCard>
            </div>

            <div className="row">
              <DownloadCard title="Literature Occurrence" svgIcon="literature">
                <p>
                  A list of objects that occur in PharmGKB literature annotations and pathways.
                </p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('occurrences.zip')} />
                    {' '}<InfoLink href="/page/downloadOccurrenceHelp" tooltip="About Occurrence archive" />
                  </li>
                </ul>
              </DownloadCard>

              <DownloadCard title="Automated Annotations" svgIcon="literature">
                <p>
                  This file contains possible relationship between a variant and a drug in a sentence from an abstract
                  in PubMed or an article in PubMed Central. Sentences are identified automatically by a text mining
                  system and have not been reviewed or validated manually by PharmGKB.
                </p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('automated_annotations.zip')} />
                    {' '}<InfoLink href="/page/downloadAutomatedAnnotationHelp" tooltip="About Automated Annotations archive" />
                  </li>
                </ul>
              </DownloadCard>
            </div>
          </section>

          <section>
            <div className="fact-section">
              <h3>Primary Data</h3>
              <p>Downloads contain term mappings and/or cross-references to multiple vocabularies, and an indication of whether the term has been annotated in PharmGKB.</p>
            </div>

            <div className="row">
              <DownloadCard title="Genes" svgIcon="gene">
                <p>A summary of the gene information used by PharmGKB and how it has been annotated.</p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('genes.zip')} />
                    {' '}<InfoLink href="/page/downloadGenesHelp" tooltip="About Genes archive" />
                  </li>
                </ul>
              </DownloadCard>

              <DownloadCard title="Variants" svgIcon="variant">
                <p>A summary of variants annotated by PharmGKB that have also been tracked in dbSNP.</p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('variants.zip')} />
                    {' '}<InfoLink href="/page/downloadAnnotatedVariantsHelp" tooltip="About Variants archive" />
                  </li>
                </ul>
              </DownloadCard>
            </div>

            <div className="row">
              <DownloadCard title="Drugs/Chemicals" svgIcon="chemical">
                <p>Summaries of chemical information annotated by PharmGKB. The list of drugs is a subset of the list of all chemicals annotated by PharmGKB.</p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('drugs.zip')} />
                    {' '}<InfoLink href="/page/downloadDrugHelp" tooltip="About Drugs archive" />
                  </li>
                  <li>
                    <FileDownloadLink file={getFile('chemicals.zip')} />
                    {' '}<InfoLink href="/page/downloadChemicalHelp" tooltip="About Chemicals archive" />
                  </li>
                </ul>
              </DownloadCard>

              <DownloadCard title="Phenotypes" svgIcon="disease">
                <p>A summary of disease and other phenotypes that have been annotated by PharmGKB.</p>
                <ul className="list list-unstyled">
                  <li>
                    <FileDownloadLink file={getFile('phenotypes.zip')} />
                    {' '}<InfoLink href="/page/downloadPhenotypeHelp" tooltip="About Phenotypes archive" />
                  </li>
                </ul>
              </DownloadCard>
            </div>
          </section>

          <section>
            <h3>Other Datasets</h3>

            <div className="row">
              <DownloadCard title="PharmGKB Branding" faIcon={['fal', 'file-image']}>
                <p>PharmGKB has a collection of logos, emblems, and other graphics that can be used when referring to PharmGKB on other sites and in publications.</p>
                <ul className="list list-unstyled">
                  <li><Link href="https://github.com/PharmGKB/pgkb-branding"><FontAwesomeIcon icon={['fab', 'github']} />PharmGKB branding repository</Link></li>
                </ul>
              </DownloadCard>

              <DownloadCard title="Papers of Interest Archive" faIcon={['fal', 'books']}>
                <p>This is an archive of all papers of interest (aka Curators Favorite Papers) from May 2006 to April 2017. All future papers of interest will be integrated into the <Link href="https://blog.clinpgx.org">ClinPGx Blog</Link>.</p>
                <ul className="list list-unstyled">
                  <li>
                    <DownloadLink href={appContext.downloadAttachmentUrl('PapersOfInterestArchive.csv')}>Papers of Interest Archive</DownloadLink>
                  </li>
                </ul>
              </DownloadCard>
            </div>

            <div className="row">
              <DownloadCard title="PharmGKB Training Exercises" faIcon={['fal', 'chalkboard-teacher']}>
                <p>These exercises are intended to help new users familiarize themselves with the PharmGKB website. Be aware that this download includes the answers to the exercises. As a result, these exercises are not recommended to be used for credit/CME.</p>
                <ul className="list list-unstyled">
                  <li>
                    <DownloadLink href={appContext.downloadAttachmentUrl('PharmGKB_Training_Exercises.zip')}>PharmGKB Training Exercises</DownloadLink>
                  </li>
                </ul>
              </DownloadCard>

              <DownloadCard title="UK Biobank Frequencies" faIcon={['fal', 'file-archive']}>
                <p>Haplotype frequencies from an analysis of the UK Biobank dataset using PharmCAT. Data from <i>Frequencies of pharmacogenomic alleles across biogeographic groups in a large-scale biobank</i> <span className="text-nowrap">(PMID: <Link href="/pmid/37757824">37757824</Link>)</span></p>
                <FileDownloadLink file={getFile('pharmgkb_haplotype_frequencies_UKBB.zip')} />
              </DownloadCard>
            </div>
          </section>
        </div>

        <div id="dl-related">
          <h2>From Related Projects</h2>
          <section>
            <p>These downloads are from published projects that PharmGKB has participated in or has some connection to.</p>
            <p>These data are not covered by the PharmGKB Data Usage Agreement. For information about about data usage restrictions please contact the original author of the data.</p>
          </section>
          <section>
            <div className="row">
              <DownloadCard title="International Tamoxifen Pharmacogenomics Consortium (ITPC)" faIcon={['fal', 'file-archive']}>
                <p>Data from <i>CYP2D6 Genotype and Adjuvant Tamoxifen: Meta-analysis of Heterogeneous Study Populations</i> (PMID:<a href="/pmid/24060820">24060820</a>):</p>
                <ul className="list list-unstyled">
                  <li><DownloadLink href={dataUrl('submission', '982025782')}>ITPC Data Set</DownloadLink></li>
                  <li><DownloadLink href={dataUrl('submission', '982025783')}>PharmGKB Analysis Code</DownloadLink></li>
                  <li><Link href="https://github.com/PharmGKB/ITPC"><FontAwesomeIcon icon={['fab', 'github']} />PharmGKB Analysis Github repository</Link></li>
                  <li><DownloadLink href={dataUrl('submission', '982025784')}>SAS Analysis Code</DownloadLink></li>
                  <li><DownloadLink href={dataUrl('submission', '982025785')}>SAS Reports</DownloadLink></li>
                </ul>
              </DownloadCard>

              <DownloadCard title="International SSRI Pharmacogenomics Consortium (ISPC)" faIcon={['fal', 'file-archive']}>
                <p>Data from <i>A genome-wide association study of antidepressant treatment response</i> (PMID:<a href="/pmid/25897834">25897834</a>):</p>
                <ul className="list list-unstyled">
                  <li><Link href="https://purl.stanford.edu/bg091gk8548"><NewTabIcon />ISPC Data Set @ Stanford Data Repository</Link></li>
                </ul>
              </DownloadCard>
            </div>

            <div className="row">
              <DownloadCard title="International Warfarin Pharmacogenetics Consortium (IWPC)" faIcon={['fal', 'file-archive']}>
                <p>Data from <i>Estimation of the warfarin dose with clinical and pharmacogenetic data</i> (PMID:<a href="/pmid/19228618">19228618</a>):</p>
                <ul className="list list-unstyled">
                  <li><DownloadLink href={dataUrl('submission', '553247439')}>IWPC Data Set</DownloadLink></li>
                  <li><DownloadLink href={dataUrl('submission', '569836982')}>Ethnicity Data Set</DownloadLink></li>
                  <li><DownloadLink href={dataUrl('submission', '561941816')}>Dosing Algorithm</DownloadLink></li>
                </ul>

                <p>Data from <i>Genetic variants associated with warfarin dose in African-American individuals: a genome-wide association study</i> (PMID:<a href="/pmid/23755828">23755828</a>):</p>
                <ul className="list list-unstyled">
                  <li><DownloadLink href={dataUrl('submission', '982047032')}>Lancet Data Set 1</DownloadLink></li>
                  <li><DownloadLink href={dataUrl('submission', '982047033')}>Lancet Data Set 2</DownloadLink></li>
                </ul>
              </DownloadCard>
              <DownloadCard title="Translational Pharmacogenetics Project (TPP)" faIcon={['fal', 'file-archive']}>
                <p>Data from <i>The Pharmacogenomics Research Network Translational Pharmacogenetics Program: Outcomes and Metrics of Pharmacogenetic Implementations Across Diverse Healthcare Systems.</i> (PMID:<a href="/pmid/28090649">28090649</a>)</p>
                <ul className="list list-unstyled">
                  <li><Link href="https://purl.stanford.edu/yp464cc5056"><NewTabIcon />Gene Look-up Tables @ Stanford Data Repository</Link></li>
                </ul>
              </DownloadCard>
            </div>
            <div className="row">
              <DownloadCard title="Data-driven prediction of drug effects and interactions" faIcon={['fal', 'file-archive']}>
                <p>Data from <em>Data-driven prediction of drug effects and interactions</em> (PMID:<a href="/pmid/22422992">22422992</a>) by Tatonetti, et al.</p>
                <p>Any questions regarding this data should be sent to Dr. Tatonetti, at <MailtoLink email="nick.tatonetti@columbia.edu">nick.tatonetti@columbia.edu</MailtoLink>.</p>
                <Link href="https://purl.stanford.edu/zq918jm7358"><NewTabIcon />Data @ Stanford Data Repository</Link>
              </DownloadCard>
            </div>
          </section>
        </div>
      </>
    );
  }

  const clearDownloadSurvey = () => {
    eraseCookie('dlSurvey');
    toast.success('Cookie cleared.');
  };

  const renderClearCookieButton = () => {
    if (!readCookie('dlSurvey')) {
      return '';
    }
    return (
      <p>
        To test download survey,{' '}
        <Button className="btn-warning btn-sm" actionHandler={clearDownloadSurvey}>
          clear survey cookie
        </Button>.
      </p>
    );
  };

  return (
    <ReadableWidthTemplate title="Downloads" className="downloads">
      <CuratorOnly>
        <p>
          These files are generated on the <strong>6th of every month</strong>.
        </p>
        {renderClearCookieButton()}
      </CuratorOnly>
      {content}
    </ReadableWidthTemplate>
  );
}
