import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';

import KyError from 'components/errors/KyError';
import Form from 'components/form';
import FormSubmit from 'components/form/Submit';
import FormText from 'components/form/Text';
import FeedbackLink from 'components/links/Feedback';
import Template from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import {validateEmail} from 'utils/formUtils';

export default function RequestPasswordReset() {
  const appContext = useAppContext();
  const [complete, setComplete] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const body = new URLSearchParams();
      body.set('email', values.email);
      await appContext.api.post('user/forgotPassword', {body});
      setComplete(true);
    } catch (err) {
      appContext.toastError(<KyError kyError={err} />);
    }
  };

  return (
    <Template title="Reset Your Password">
      <p>
        Forgot your password? Enter your email address and we will send you a recovery link.
      </p>
      <section>
        <Form onSubmit={handleSubmit}>
          <FormText
            name="email"
            label="Email"
            help="The email you originally registered with us"
            required={true}
            validation={{
              email: (v) => validateEmail(v) || 'Please enter a valid email.',
            }}
          />
          <FormSubmit>Reset Password</FormSubmit>
        </Form>
      </section>
      {complete && (
        <section className="mt-3">
          <h3 className="text-success"><FontAwesomeIcon icon="check-circle" /> Success</h3>
          <p>
            Your password reset request has been received. Check your email for a link to reset
            your password.
          </p>
          <p>
            If you do not receive an email, please check your spam folder or <FeedbackLink />.
          </p>
        </section>
      )}
    </Template>
  );
}
