import PropTypes from 'prop-types';
import {useEffect} from 'react';

import Loading from 'components/Loading';
import useSearchBar from 'components/Search/Bar/Provider';
import SideNav from 'components/SideNav';
import {getMenuItems, getTabComponent} from 'components/SideNav/utils';
import ErrorMessage from 'components/errors/ErrorMessage';
import KyError from 'components/errors/KyError';
import NotFound from 'components/shared/error/not_found';
import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import usePediatricContext from 'context/Pediatric';
import {useGet} from 'helpers/KyHooks';
import HaplotypeOverviewTab from 'pages/Haplotype/OverviewTab';


const propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.string,
  loadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
/**
 * Renders haplotype page.
 */
export default function HaplotypePage({id, section = 'overview', loadId = 0}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const searchBar = useSearchBar();
  const showOverview = !section || section === 'overview';

  const {response, error} = useGet(`site/haplotype/${id}`, {
    cacheResponse: !appContext.isPreview,
  }, {
    loadId,
  });
  useEffect(() => {
    if (response) {
      searchBar.initializeSearchBar([{id, objCls: 'haplotype', name: response.data.haplotype.symbol}]);
    }
  }, [response]);

  let name = `Haplotype ${id}`;
  let body = <Loading />;
  let counts = {};
  if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }
    body = <KyError kyError={error} />;

  } else if (response) {
    const {
      haplotype, isCpic, isPharmVarAllele, description, noDefinition, alleleFile, sourceId, vips, frequency,
    } = response.data;
    counts = response.data.counts;
    name = response.data.haplotype.symbol;

    if (showOverview) {
      body = (
        <HaplotypeOverviewTab
          haplotype={haplotype}
          isCpic={isCpic}
          isPharmVarAllele={isPharmVarAllele}
          description={description}
          counts={counts}
          vips={vips}
          noDefinition={noDefinition}
          alleleFile={alleleFile}
          sourceId={sourceId}
          frequency={frequency}
        />
      );
    }
  }
  const Comp = getTabComponent(section, pediatricContext.isPediatricMode);
  if (Comp) {
    body = <Comp id={id} objCls="haplotype" name={name} />;
  } else if (!showOverview) {
    body = <ErrorMessage message={`Unsupported tab "${section}"`} />;
  }

  const baseUrl = Types.haplotype.url(id);
  const menuItems = getMenuItems('haplotype', baseUrl, counts);

  return (
    <FullWidthResourceTemplate id={id} type="haplotype" name={name}>
      <SideNav items={menuItems}>
        {body}
      </SideNav>
    </FullWidthResourceTemplate>
  );
}
HaplotypePage.propTypes = propTypes;
