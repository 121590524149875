import PropTypes from 'prop-types';
import {useEffect} from 'react';

import Loading from 'components/Loading';
import useSearchBar from 'components/Search/Bar/Provider';
import SideNav from 'components/SideNav';
import {getMenuItems, getTabComponent} from 'components/SideNav/utils';
import ErrorMessage from 'components/errors/ErrorMessage';
import KyError from 'components/errors/KyError';
import NotFound from 'components/shared/error/not_found';
import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import usePediatricContext from 'context/Pediatric';
import {useGet} from 'helpers/KyHooks';
import GeneHaplotypesTab from 'pages/Gene/HaplotypesTab';
import GeneOverviewTab from 'pages/Gene/OverviewTab';


const propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.string,
  loadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
/**
 * Renders gene page.
 */
export default function GenePage({id, section = 'overview', loadId = 0}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const searchBar = useSearchBar();
  const showOverview = !section || section === 'overview';

  const {response, error} = useGet(`site/gene/${id}`, {
    cacheResponse: !appContext.isPreview,
  }, {
    loadId,
  });
  useEffect(() => {
    if (response) {
      searchBar.initializeSearchBar([{id, objCls: 'gene', name: response.data.gene.symbol}]);
    }
  }, [response]);

  let name = `Gene ${id}`;
  let body = <Loading />;
  let alleleType = 'Named Alleles';
  let counts = {};
  if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }
    body = <KyError kyError={error} />;

  } else if (response) {
    const {
      gene, needsApproval, isPediatric, description, frequencySources, pedSummary, acmgIncFind, vip,
    } = response.data;
    counts = response.data.counts ?? {};
    name = gene.symbol;
    alleleType = gene.alleleType;

    const Comp = getTabComponent(section, pediatricContext.isPediatricMode);
    if (showOverview) {
      body = (
        <GeneOverviewTab
          gene={gene}
          counts={counts}
          needsApproval={needsApproval}
          vip={vip}
          description={description}
          pedSummary={pedSummary}
          acmgFind={acmgIncFind}
          isPediatric={isPediatric}
          frequencySources={frequencySources}
        />
      );
    } else if (Comp) {
      body = <Comp id={id} objCls="gene" name={name} />;
    } else if (section === 'haplotype') {
      body = <GeneHaplotypesTab id={id} />;
    } else if (!showOverview) {
      body = <ErrorMessage message={`Unsupported tab "${section}"`} />;
    }
  }

  const baseUrl = Types.gene.url(id);
  const menuItems = getMenuItems('gene', baseUrl, counts, alleleType);

  return (
    <FullWidthResourceTemplate id={id} type="gene" name={name}>
      <SideNav items={menuItems}>
        {body}
      </SideNav>
    </FullWidthResourceTemplate>
  );
}
GenePage.propTypes = propTypes;
