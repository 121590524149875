import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {filter, map, orderBy, size} from 'lodash';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

import Button from 'components/Button';
import DrugInteraction from 'components/Literature/DrugInteraction';
import InteractionSubhead from 'components/Literature/InteractionSubhead';
import Loading from 'components/Loading';
import Section from 'components/Section';
import KyError from 'components/errors/KyError';
import useAppContext from 'conf/AppContext';

export default function DrugInteractionList({interactionType, literatureId}) {
  const appContext = useAppContext();
  const [loading, setLoading] = useState(false);
  const [unapproved, setUnapproved] = useState(false);
  const [interactions, setInteractions] = useState([]);
  useEffect(() => {
    let mounted = true;
    const load = async () => {
      try {
        setLoading(true);
        const resp = await appContext.api.get(`site/${interactionType}Interaction/${literatureId}`, {parseJson: true});
        if (mounted) {
          setInteractions(orderBy(
            resp.data?.interactions,
            ['interacts', (i) => i.sourceDrug?.name, (i) => i.targetDrug?.name],
            ['desc', 'asc', 'asc'],
          ));
          setUnapproved(size(resp.data?.approveIds) !== 0);
        }
        setLoading(false);
      } catch (err) {
        appContext.toastError(<KyError kyError={err} />);
        setLoading(false);
      }
    };
    load();
    return () => {
      mounted = false;
    };
  }, [interactionType, literatureId]);


  const titlePrefix = interactionType === 'drugDrug' ? 'Drug-Drug Interactions' : 'Drug-Gene Interactions';
  const handleDelete = (id) => () => setInteractions(filter(interactions, (i) => i.id !== id));
  const handleApprove = async () => {
    try {
      await appContext.api.put(`curation/${interactionType}Interaction/${literatureId}/approve`);
      setUnapproved(false);
      toast.success('Approved');
    } catch (err) {
      appContext.toastError(<KyError kyError={err} />);
    }
  };


  let body;
  if (loading) {
    return <Loading />;
  }
  if (size(interactions) === 0) {
    if (!appContext.isPreview) {
      return null;
    }
    // show component in preview because of edit controls
    body = <p className="empty">None.</p>;
  } else {
    body = (
      <>
        <InteractionSubhead id={literatureId} type={`${interactionType}Interactions`} />
        {map(interactions, (i) => <DrugInteraction key={i.id} {...i} onDelete={handleDelete(i.id)} />)}
      </>
    );
  }

  return (
    <Section
      id={interactionType}
      title={`${titlePrefix} (${size(interactions)})`}
      editControls={(
        <>
          <Button href={`/edit/${interactionType}Interaction?litId=${literatureId}`}><FontAwesomeIcon icon="plus" /> Add</Button>
          <Button actionHandler={handleApprove} className="btn-warning" disabled={!unapproved}><FontAwesomeIcon icon="check" /> Approve</Button>
        </>
      )}
    >
      {body}
    </Section>
  );
}
