import clsx from 'clsx';
import PropTypes from 'prop-types';

import Tag from 'components/Tag/index';

const propTypes = {
  tier: PropTypes.string,
  className: PropTypes.string,
};

export default function AmpTierTag({tier, className}) {
  return (
    <Tag
      className={clsx('tag--secondary', className)}
      tooltipUrl="/ampAllelesToTest"
      tooltipText="Learn more about AMP tiers"
    >
      AMP {tier}
    </Tag>
  );
}
AmpTierTag.propTypes = propTypes;
