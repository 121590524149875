import clsx from 'clsx';
import {forEach, lowerCase, map} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useMemo, useRef, useState} from 'react';

import BarChart from 'components/Chart/LazyBar';
import Loading from 'components/Loading';
import {accessionIdProps} from 'conf/types';

/**
 * Sort order for agencies in the bar chart
 */
const LabelSourceOrder = {
  FDA: 1,
  EMA: 2,
  HCSC: 3,
  Swissmedic: 4,
  PMDA: 5,
};

/**
 * Colors in the bar chart for level
 */
const LabelLevelColor = {
  'Testing Required': '#c53b3b',
  'Testing Recommended': '#ffc107',
  'Actionable PGx': '#0ABC72',
  'Informative PGx': '#2A74DF',
  'No Clinical PGx': '#5BC0DE',
  'Criteria Not Met': '#657188',
};

const propTypes = {
  labelAnnotations: PropTypes.arrayOf(PropTypes.shape({
    drug: accessionIdProps,
  })),
  filterChangeFn: PropTypes.func,
  className: PropTypes.string,
};

/**
 * Renders label annotations bat chart.
 *
 * @param {object} props - props container
 * @param {Array} [props.labelAnnotations]
 * @param {Function} [props.filterChangeFn]
 * @param {string} [props.className]
 * @return {ReactElement | null}
 */
export default function LabelAnnotationsBarChart({labelAnnotations, filterChangeFn, className}) {

  const [yAxis, setYAxis] = useState('source');
  const ref = useRef(yAxis);

  useEffect(() => {
    ref.current = yAxis;
  }, [yAxis]);

  const dependencyKey = map(labelAnnotations, ({drug}) => drug.id).join(',');

  const chartData = useMemo(() => {
    if (labelAnnotations) {
      if (yAxis === 'source') {
        return createSourceChartData(labelAnnotations);
      } else {
        return createPrescribingChartData(labelAnnotations);
      }
    } else {
      return null;
    }
  }, [yAxis, dependencyKey]);


  if (!chartData) {
    return (
      <div className="laFigure laFigure--loading figure figure--floatRight">
        <Loading />
      </div>
    );
  }

  if (chartData.length === 0) {
    return null;
  }

  const handleClick = (label, elementLabel) => {
    if (filterChangeFn) {
      filterChangeFn([
        {
          id: lowerCase(label),
          value: elementLabel,
        },
      ]);
    }
  };

  const handleChange = (e) => {
    setYAxis(e.target.value);
    if (filterChangeFn) {
      filterChangeFn(null);
    }
  };

  return (
    <figure key={dependencyKey} className={clsx('laFigure', className)}>
      <BarChart
        data={chartData}
        stacked={true}
        horizontal={true}
        clickHandler={yAxis === 'source' ? handleClick : null}
      />
      <figcaption className="text-center text-muted">
        Count of annotations by{' '}
        <select onChange={handleChange}>
          <option value="source">source</option>
          <option value="prescribing">prescribing info</option>
        </select>
      </figcaption>
    </figure>
  );
}
LabelAnnotationsBarChart.propTypes = propTypes;

/**
 * create source data for bar chart.
 *
 * @param {Array} labelAnnotations
 * @return {object}
 */
function createSourceChartData(labelAnnotations) {
  const labels = [];

  const testingRequiredData = {
    label: 'Testing Required',
    data: [],
    backgroundColor: [],
  };

  const testingRecommendedData = {
    label: 'Testing Recommended',
    data: [],
    backgroundColor: [],
  };

  const actionablePgxData = {
    label: 'Actionable PGx',
    data: [],
    backgroundColor: [],
  };

  const informativePgxData = {
    label: 'Informative PGx',
    data: [],
    backgroundColor: [],
  };

  const noClinicalPgxData = {
    label: 'No Clinical PGx',
    data: [],
    backgroundColor: [],
  };

  const criteriaNotMetData = {
    label: 'Criteria Not Met',
    data: [],
    backgroundColor: [],
  };

  forEach(LabelSourceOrder, (value, key) => {
    let testingRequired = 0;
    let testingRecommended = 0;
    let actionablePgx = 0;
    let informativePgx = 0;
    let noClinicalPgx = 0;
    let criteriaNotMet = 0;

    for (let x = 0; x < labelAnnotations.length; x += 1) {
      const labelAnn = labelAnnotations[x];
      const keys = Object.keys(labelAnn.labels);

      for (let k = 0; k < keys.length; k += 1) {
        if (keys[k] === key) {
          const source = labelAnn.labels[key];
          for (let i = 0; i < source.length; i += 1) {
            if (source[i].status === 'Testing Required') {
              testingRequired += 1;
            } else if (source[i].status === 'Testing Recommended') {
              testingRecommended += 1;
            } else if (source[i].status === 'Actionable PGx') {
              actionablePgx += 1;
            } else if (source[i].status === 'Informative PGx') {
              informativePgx += 1;
            } else if (source[i].status === 'No Clinical PGx') {
              noClinicalPgx += 1;
            } else if (source[i].status === 'Criteria Not Met') {
              criteriaNotMet += 1;
            }
          }
        }
      }
    }

    labels.push(key);
    testingRequiredData.data.push(testingRequired);
    testingRequiredData.backgroundColor.push(LabelLevelColor['Testing Required']);

    testingRecommendedData.data.push(testingRecommended);
    testingRecommendedData.backgroundColor.push(LabelLevelColor['Testing Recommended']);

    actionablePgxData.data.push(actionablePgx);
    actionablePgxData.backgroundColor.push(LabelLevelColor['Actionable PGx']);

    informativePgxData.data.push(informativePgx);
    informativePgxData.backgroundColor.push(LabelLevelColor['Informative PGx']);

    noClinicalPgxData.data.push(noClinicalPgx);
    noClinicalPgxData.backgroundColor.push(LabelLevelColor['No Clinical PGx']);

    criteriaNotMetData.data.push(criteriaNotMet);
    criteriaNotMetData.backgroundColor.push(LabelLevelColor['Criteria Not Met']);
  });

  return {
    labels,
    datasets: [
      testingRequiredData,
      testingRecommendedData,
      actionablePgxData,
      informativePgxData,
      noClinicalPgxData,
      criteriaNotMetData,
    ],
  };
}

/**
 * create prescribing data for bar chart.
 *
 * @param {Array} labelAnnotations
 * @return {object}
 */
function createPrescribingChartData(labelAnnotations) {
  const labels = [];

  const prescribingInfoData = {
    label: 'Prescribing Info',
    data: [],
    backgroundColor: [],
  };

  const noPrescribingInfoData = {
    label: ' No Prescribing Info',
    data: [],
    backgroundColor: [],
  };

  forEach(LabelSourceOrder, (value, key) => {
    let prescribing = 0;
    let noPrescribing = 0;

    for (let x = 0; x < labelAnnotations.length; x += 1) {
      const labelAnn = labelAnnotations[x];
      const keys = Object.keys(labelAnn.labels);

      for (let k = 0; k < keys.length; k += 1) {
        if (keys[k] === key) {
          const source = labelAnn.labels[key];
          for (let i = 0; i < source?.length; i += 1) {
            if (source[i].prescribing) {
              prescribing += 1;
            } else {
              noPrescribing += 1;
            }
          }
        }
      }
    }
    labels.push(key);

    prescribingInfoData.data.push(prescribing);
    // using blue from _variables.scss
    prescribingInfoData.backgroundColor.push('#2A74DF');

    noPrescribingInfoData.data.push(noPrescribing);
    // using $grey30 from _variables.scss
    noPrescribingInfoData.backgroundColor.push('#C5CAD2');
  });

  return {labels, datasets: [prescribingInfoData, noPrescribingInfoData]};
}
