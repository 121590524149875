import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {find, forEach, isEmpty, map} from 'lodash';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';

import ButtonPanel from 'components/Button/Panel';
import {createText} from 'components/LinksTab/index';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import Form from 'components/form';
import FormCancel from 'components/form/Cancel';
import FormCheckboxGroup from 'components/form/CheckboxGroup';
import FormSubmit from 'components/form/Submit';
import Link from 'components/links/Link';
import useAppContext from 'conf/AppContext';
import {LinkOutResource} from 'conf/enums';
import {useGet} from 'helpers/KyHooks';

const propTypes = {
  objCls: PropTypes.string.isRequired,
  objId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
/**
 * Modal form updating links
 *
 * @param {object} props - props container
 * @param {string} props.objCls
 * @param {string} props.objId
 * @param {Function} props.onSave - function to call on save
 * @param {Function} props.onClose - function to call on cancel
 * @return {JSX.Element}
 */
export default function LinksTabForm({objCls, objId, onSave, onClose}) {
  const appContext = useAppContext();
  const {response, error} = useGet(`curation/${objCls}/${objId}/linkOuts/_update`);

  const handleSubmit = async (values) => {
    const data = [];
    forEach(Object.keys(values), (resource) => {
      if (values[resource].length > 0) {
        for (let x = 0; x < values[resource].length; x += 1) {
          if (LinkOutResource.ontology.includes(LinkOutResource.displayName[resource])) {
            const {name} = find(response.data.links[resource], (link) => link.resourceId === values[resource][x]);
            data.push({
              resource,
              resourceId: values[resource][x],
              name,
            });
          } else {
            data.push({
              resource,
              resourceId: values[resource][x],
            });
          }
        }
        return data;
      }
    });

    try {
      const rez = await appContext.api.put(`curation/${objCls}/${objId}/linkOuts`, {
        json: data,
        parseJson: true,
      });
      onSave(rez.data);
      toast.success('Links updated');
    } catch (err) {
      appContext.toastError(<KyError kyError={err} />);
    }
  };

  let body = <Loading />;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    if (isEmpty(response?.data?.links)) {
      return (
        <p className="empty mb-4">No new links found.</p>
      );
    }

    const {linkOptions, defaultValues} = createFormData(response.data.links);
    body = (
      <>
        <h3>Update New Links</h3>
        <Form
          mode="onSubmit"
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
        >
          <div className="linksTab__xrefs">
            {map(Object.keys(linkOptions), (src) => (
              <FormCheckboxGroup
                key={src}
                name={src}
                label={<h4>{src}</h4>}
                options={linkOptions[src]}
                className="linksTab__xrefs__resource"
              />
            ))}
          </div>
          <ButtonPanel
            buttons={[
              <FormSubmit key="save">Save</FormSubmit>,
              <FormCancel key="cancel" actionHandler={onClose}>Cancel</FormCancel>,
            ]}
          />
        </Form>
      </>
    );
  }

  return (
    <div className="linksTab">
      {body}
    </div>
  );
}
LinksTabForm.propTypes = propTypes;

function createFormData(links) {
  const linkOptions = {};
  const defaultValues = {};

  forEach(Object.keys(links), (link) => {
    if (link === 'Genetic Testing Registry') {
      return;
    }
    if (links[link].length > 0) {
      linkOptions[link] = [];
      defaultValues[link] = [];

      for (let x = 0; x < links[link].length; x += 1) {
        const {id, resource, name, resourceId, _url: url, isAutomatic} = links[link][x];
        const text = createText(resource, name, resourceId);
        const labelIcon = isAutomatic && <FontAwesomeIcon icon={['fal', 'robot']} size="xs" className="icon--superscript" title="Added programatically" />;
        const labelText = url ? <Link href={url}>{text}</Link> : text;
        const label = isAutomatic ? <>{labelText} {labelIcon}</> : labelText;
        linkOptions[link].push({label, value: resourceId});
        if (id !== -1) {
          defaultValues[link] = [...defaultValues[link], resourceId];
        }
      }
    }
  });
  return {linkOptions, defaultValues};
}
