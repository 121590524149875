import React from 'react';
import PropTypes from 'prop-types';

import HtmlContainer from 'components/shared/html_container';
import Types from 'conf/types';
import EditControls from 'components/edit/EditControls';
import Button from 'components/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import VipTierTag from 'components/Tag/VipTier';

/**
 * A component for showing VIP information in a visually distinct and highlighted section in a gene overview tab
 *
 * For chemicals or variants, use VipRelatedSummary
 *
 * @param {object} props - props container
 * @param {string} props.id - the PA ID of the VIP annotation, used to construct link
 * @param {string} props.tier - the Tier descriptor for this VIP, optional
 * @param {string} props.summary - a short summary, in HTML, optional
 * @param {string} props.geneId - the PA ID of the gene, required
 * @return {React.ReactNode} a block VIP summary node
 */
export default function VipSummary({id, tier, summary, geneId}) {
  if (!id) {
    return (
      <EditControls>
        <Button href={`/edit/vip?targetId=${geneId}`}><FontAwesomeIcon icon="plus" /> Add VIP</Button>
      </EditControls>
    );
  } else {
    return (
      <section className="vip-summary">
        <header>
          <h4 className="factLabel">
            Overview
          </h4>
        </header>
        {tier && <VipTierTag tier={tier} />}
        <span className="fact-content">
          {summary && <HtmlContainer html={summary} />}
          <p className="vip-summary-more"><a href={Types.vipGene.url(id)}>more of the Very Important Pharmacogene (VIP) summary…</a></p>
        </span>
      </section>
    );
  }
}
VipSummary.propTypes = {
  id: PropTypes.string,
  tier: PropTypes.string,
  summary: PropTypes.string,
  geneId: PropTypes.string,
};
