import PropTypes from 'prop-types';

import LiteratureTab from 'components/Literature/Tab';
import Loading from 'components/Loading';
import SideNav from 'components/SideNav';
import KyError from 'components/errors/KyError';
import {totalOnlyResourceCount} from 'components/resource/resourceCounts';
import NotFound from 'components/shared/error/not_found';
import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import Types from 'conf/types';
import {useGet} from 'helpers/KyHooks';
import VipOverviewTab from 'pages/Vip/OverviewTab';
import VipGeneRelationshipsTab from 'pages/Vip/RelationshipsTab';


const propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.string,
};

export default function VipPage({id, section = 'overview'}) {
  const baseUrl = Types.vipGene.url(id);
  const menuItems = [
    {url: `${baseUrl}/overview`, label: 'Overview', otherUrls: [baseUrl]},
    {url: `${baseUrl}/literature`, label: 'Key Literature'},
    {url: `${baseUrl}/relationships`, label: 'Relationships'},
  ];

  const {response, error} = useGet(`site/vip/${id}`);

  let name = 'Very Important Pharmacogene';
  let body = <Loading />;
  if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }
    body = <KyError kyError={error} />;
  } else if (response) {
    const {vip, literature, approved, published} = response.data;
    name = `Very Important Pharmacogene: ${vip.target.symbol}`;
    menuItems[1].count = totalOnlyResourceCount(vip?.keyLiterature?.length);
    menuItems[2].count = totalOnlyResourceCount(vip?.relationships?.length);

    switch (section) {
      case 'literature':
        body = (
          <LiteratureTab
            id={id}
            objCls={Types.vipGene.displayType}
            title="Key Literature"
            blurb=" "
            literature={literature}
          />
        );
        break;

      case 'relationships':
        body = <VipGeneRelationshipsTab relationships={vip?.relationships} />;
        break;

      default:
        body = <VipOverviewTab vip={vip} approved={approved} published={published} />;
    }
  }


  return (
    <FullWidthResourceTemplate id={id} type="vipGene" name={name}>
      <SideNav items={menuItems}>
        {body}
      </SideNav>
    </FullWidthResourceTemplate>
  );
}
VipPage.propTypes = propTypes;
