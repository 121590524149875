import PropTypes from 'prop-types';
import {useEffect} from 'react';

import Loading from 'components/Loading';
import useSearchBar from 'components/Search/Bar/Provider';
import SideNav from 'components/SideNav';
import {getMenuItems, getTabComponent} from 'components/SideNav/utils';
import ErrorMessage from 'components/errors/ErrorMessage';
import KyError from 'components/errors/KyError';
import NotFound from 'components/shared/error/not_found';
import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import usePediatricContext from 'context/Pediatric';
import {useGet} from 'helpers/KyHooks';
import DiseaseOverviewTab from 'pages/Disease/OverviewTab';

const propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.string,
  loadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
/**
 * Renders disease page.
 *
 * @param {object} props - props container
 * @param {string} props.id - disease's id
 * @param {string} props.section - select sidenav for disease
 * @param {string|number} props.loadId - used to force re-fetch of the data
 * @return {JSX.Element}
 */
export default function DiseasePage({id, section = 'overview', loadId = 0}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const searchBar = useSearchBar();
  const showOverview = !section || section === 'overview';
  
  const {response, error} = useGet(`site/disease/${id}`, {
    cacheResponse: !appContext.isPreview,
  }, {
    loadId,
  });
  
  useEffect(() => {
    if (response) {
      searchBar.initializeSearchBar([{id, objCls: 'disease', name: response.data.disease.name}]);
    }
  }, [response]);
  
  let name = `Disease ${id}`;
  let body = <Loading />;
  let counts = {};
  if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }
    body = <KyError kyError={error} />;
  } else if (response) {
    const {disease, needsApproval, description} = response.data;
    counts = response.data.counts ?? {};
    name = disease.name;

    if (showOverview) {
      body = (
        <DiseaseOverviewTab
          disease={disease}
          counts={counts}
          needsApproval={needsApproval}
          description={description}
        />
      );
    }
  }
  const Comp = getTabComponent(section, pediatricContext.isPediatricMode);
  if (Comp) {
    body = <Comp id={id} objCls="disease" name={name} />;
  } else if (!showOverview) {
    body = <ErrorMessage message={`Unsupported tab "${section}"`} />;
  }
    
  const baseUrl = Types.disease.url(id);
  const menuItems = getMenuItems('disease', baseUrl, counts);
  
  return (
    <FullWidthResourceTemplate id={id} type="disease" name={name}>
      <SideNav items={menuItems}>
        {body}
      </SideNav>
    </FullWidthResourceTemplate>
  );

}
DiseasePage.propTypes = propTypes;
