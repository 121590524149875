import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {startsWith} from 'lodash';

import useAppContext from 'conf/AppContext';


/**
 * Renders floating feedback button (unless already on feedback page).
 *
 * @return {node} the feedback node
 */
export default function FeedbackButton() {
  const appContext = useAppContext();
  if (startsWith(appContext.getCurrentPath(), '/feedback')) {
    return null;
  }

  const handleSubmit = (event) => {
    if (event.type === 'keydown' && event.key !== 'Enter' && event.key !== ' ') {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    appContext.redirect(`/feedback?prevUrl=${encodeURIComponent(appContext.getCurrentPath())}`);
  };

  return (
    <div
      title="Submit feedback"
      aria-label="Submit feedback"
      className="feedbackButton d-none d-sm-block"
      role="button"
      onClick={handleSubmit}
      onKeyDown={handleSubmit}
      tabIndex={0}
    >
      <div className="feedbackButton__icon">
        <FontAwesomeIcon icon="envelope" />
      </div>
    </div>
  );
}
