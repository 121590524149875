// These variables are set by Webpack's DefinePlugin:
/* global ENV, SENTRY_DSN, SENTRY_RELEASE */
import {RewriteFrames} from '@sentry/integrations';
import {init} from '@sentry/react';
import {Integrations} from '@sentry/tracing';

// initializes Sentry, which should be done as soon as possible.
if (SENTRY_DSN) {
  // initialize Sentry ASAP
  const sentryProps = {
    dsn: SENTRY_DSN,
    environment: ENV,
    ignoreErrors: [
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      /^ResizeObserver loop limit exceeded$/,
      /^ResizeObserver loop completed with undelivered notifications\.$/,
      /SecurityError: Failed to execute 'pushState' .*file:\/\//,
      /SecurityError: Failed to execute 'pushState' on 'History'.*pharmgkb-org\.translate\.goog'.*/,
      // not solvable: https://github.com/facebook/react/issues/11538
      /NotFoundError: Failed to execute 'insertBefore' on 'Node':/,
      /AbortError: Fetch is aborted/,

      // from https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    // from https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  };
  if ((ENV === 'production' || ENV === 'preview' || ENV === 'beta') &&
    typeof SENTRY_RELEASE !== 'undefined') {
    sentryProps.release = SENTRY_RELEASE.id;
    sentryProps.allowUrls = [
      /https:\/\/(.*\.)?pharmgkb\.org/,
    ];
    sentryProps.integrations = [
      new Integrations.BrowserTracing(),
      new RewriteFrames(),
    ];
    sentryProps.tracesSampleRate = 0.05;
  }
  init(sentryProps);
}
