/* eslint-env browser */
import {map, startsWith} from 'lodash';
import {useCallback, useEffect} from 'react';
import {toast} from 'react-toastify';

import {isIgnorableKeydown} from 'components/Button';
import useModalService from 'components/shared/ModalService';
import TourSteps from 'components/tour/steps';
import useAppContext from 'conf/AppContext';
import logger from 'conf/Logger';

const homePageRegex = /^\/(\?.*)?$/;
const overviewRegex = /^\/(chemical|pathway|vip)\/PA[0-9]+(\/overview)?(\?.*)?$/;
const tourTypeRegex = /^\/([A-Za-z]+)\/[A-Z0-9]+(\?.*)?/;


/**
 * Checks url to see if page has a tour.
 *
 * @return {boolean}
 */
export const hasTour = (path) =>
  homePageRegex.test(path) ||
  overviewRegex.test(path) ||
  startsWith(path, '/guidelineAnnotation/PA166104945') ||
  startsWith(path, '/labelAnnotation/PA166104829') ||
  startsWith(path, '/variantAnnotation/1445362018') ||
  startsWith(path, '/clinicalAnnotation/981419260');


/**
 * Checks tour type for setting correct steps.
 *
 * @param {string} path
 * @return {string | null}
 */
export function getTourType(path) {
  if (homePageRegex.test(path)) {
    return 'home';
  }
  const match = tourTypeRegex.exec(path);
  if (match) {
    return match[1];
  }
  return null;
}


/**
 * Handle click/keyboard events to start tour.
 */
export const tourEventHandler = (event) => {
  if (isIgnorableKeydown(event)) {
    return;
  }
  event.preventDefault();
  event.stopPropagation();
  triggerTour();
};

function triggerTour() {
  document.dispatchEvent(new Event('tour-start'));
}

async function _startTour(type, handleContinue) {
  try {
    const module = await import(/* webpackChunkName: "introjs" */'intro.js/intro');
    const intro = module.default();
    intro.setOptions({
      steps: TourSteps[type],
      showStepNumbers: false,
      hidePrev: true,
    });
    intro.oncomplete(() => {
      handleContinue();
    });
    intro.start();

  } catch (error) {
    logger.error(error);
  }
}

function nextStepQuery(type) {
  if (type === 'home') {
    return {
      query: (
        <>
          <p>Continue exploring PharmGKB!</p>
          <p>Would you like to continue the tour by visiting one of our drug pages?</p>
        </>
      ),
      link: '/chemical/PA451906?tour=true',
    };
  } else {
    let link = '/whatIsPharmgkb';
    switch (type) {
      case 'guidelineAnnotation':
        link += '/annotations';
        break;
      case 'labelAnnotation':
        link += '/pathways';
        break;
      case 'pathway':
        link += '/vips';
        break;
      case 'vip':
        link += '/clinicalAnnotations';
        break;
      case 'clinicalAnnotation':
        link += '/variantAnnotations';
        break;
      default:
    }
    return {
      query: 'Want more information or guided tours of other specific content types, ' +
        'such as labels, guidelines or annotations? Head over to our overview page!',
      link,
    };
  }
}

const waitForElement = (selector, timestamp) => new Promise((resolve) => {
  const getElement = () => {
    const element = document.querySelector(selector);
    if (element) {
      resolve();
    } else {
      // keep waiting for 10 seconds
      if (Date.now() < timestamp + 10000) {
        requestAnimationFrame(getElement);
      } else {
        toast.error('Sorry, the tour cannot be started because the page did not finish loading.');
      }
    }
  };
  getElement();
});


/**
 * This hook preps a page for a tour.
 *
 * @param {string} type
 * @return {Function} - function to call to indicate that the page is ready for the tour
 */
export const useTour = (type) => {
  const appContext = useAppContext();
  const modalService = useModalService();
  const path = appContext.getCurrentPath();

  /**
   * Call to indicate that the page is ready for the tour.
   *
   * @typedef {Function} readyForTour
   * @param {string[]} [selectors] - list of selectors that must be loaded before starting tour
   */
  const readyForTour = useCallback((selectors) => {
    if (!path.endsWith('?tour=true')) {
      return;
    }
    if (selectors && selectors.length > 0) {
      const timestamp = Date.now();
      Promise.all(map(selectors, (s) => waitForElement(s, timestamp)))
        .then(() => {
          triggerTour();
        });
    } else {
      triggerTour();
    }
  }, [path]);

  useEffect(() => {
    if (!appContext.inBrowser || !hasTour(path)) {
      return;
    }
    // check to make sure that subcomponent isn't also requesting tour
    if (type !== 'home' && !startsWith(path, `/${type}`) && type !== 'chemicalNoPediatric') {
      return;
    }

    const handleStart = (e) => {
      e.preventDefault();
      e.stopPropagation();

      _startTour(type, () => {
        const nextStep = nextStepQuery(type);
        modalService.confirm({
          content: nextStep.query,
          yesLabel: 'Yes, Continue',
          yesHandler: () => {
            appContext.redirect(nextStep.link);
            window.scrollTo(0, 0);
          },
          noLabel: 'No, I\'m Done',
          style: {
            width: '26rem',
          },
        });
      })
        .catch((error) => {
          logger.warn('Error starting tour', error);
        });
      window.scrollTo(0, 0);
    };

    document.addEventListener('tour-start', handleStart);
    return () => {
      document.removeEventListener('tour-start', handleStart);
    };
  }, [path]);

  // call this method after page has finished rendering
  return readyForTour;
};
