import {useState} from 'react';

import './index.scss';
import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import KyError from 'components/errors/KyError';
import CuratorOnly from 'components/shared/curator_only';
import FullFluidWidthTemplate from 'components/templates/FullFluidWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';
import LabelAnnotationsBarChart from 'pages/LabelAnnotations/BarChart';
import LabelAnnotationDrugTable from 'pages/LabelAnnotations/DrugTable';

export default function LabelAnnotationsPage() {
  const appContext = useAppContext();

  const {response, error} = useGet('site/labelsByDrug', {
    cacheResponse: !appContext.isPreview,
  });

  const [filter, setFilter] = useState(null);

  let table = <Loading />;
  let barChart = (
    <div className="laFigure laFigure--loading figure figure--floatRight">
      <Loading />
    </div>
  );
  if (error) {
    table = <KyError kyError={error} />;
  } else if (response) {
    table = (
      <LabelAnnotationDrugTable
        data={response?.data}
        updatedFilters={filter}
      />
    );
    barChart = (
      <LabelAnnotationsBarChart
        labelAnnotations={response?.data}
        filterChangeFn={setFilter}
        className="figure--floatRight"
      />
    );
  }

  return (
    <FullFluidWidthTemplate className="labelAnnotationsPage" title="Drug Label Annotations">
      <div className="container mb-3 clearfix">
        {barChart}
        <InlinePage id="drugLabelListingHeader" />
      </div>
      {listAllLabelAnnotations()}
      {table}
    </FullFluidWidthTemplate>
  );
}

export function listAllLabelAnnotations() {
  return (
    <CuratorOnly>
      <a href="/edit/labelAnnotations">Curation Dashboard: List All Label Annotations</a>
    </CuratorOnly>
  );
}
