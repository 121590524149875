import {useEffect} from 'react';

import chemicalBackgroundImage from 'images/homepage/background-chemical.svg';
import guidelineBackgroundImage from 'images/homepage/background-dosing-guideline.svg';
import labelBackgroundImage from 'images/homepage/background-drug-label.svg';
import geneBackgroundImage from 'images/homepage/background-gene.svg';
import literatureBackgroundImage from 'images/homepage/background-literature.svg';
import lineCapImage from 'images/homepage/line-cap.svg';
import lineMiddleImage from 'images/homepage/line-middle.svg';
import ddrxIcon from 'images/icons/ddrx.svg';
import gsiIcon from 'images/icons/gsi.svg';

import Button from 'components/Button';
import AnnotationsList from 'components/Home/AnnotationsList';
import LatestNews from 'components/Home/LatestNews';
import Partners from 'components/Home/Partners';
import ResourceCounts from 'components/Home/ResourceCounts';
import SearchBar from 'components/Search/Bar';
import InfoIcon from 'components/icons/Info';
import PediatricIcon from 'components/icons/Pediatric';
import Link from 'components/links/Link';
import Svg from 'components/shared/Svg';
import {useTour} from 'components/tour/hook';
import logger from 'conf/Logger';
import {useGet} from 'helpers/KyHooks';


export default function HomePage({}) {

  const {response: resourceStats, error: statsError} = useGet('report/stats', {
    cacheResponse: true,
  });
  if (statsError) {
    logger.error(statsError);
  }

  const readyForTour = useTour('home');
  useEffect(() => {
    if (resourceStats) {
      readyForTour();
    }
  }, [readyForTour, resourceStats]);

  return (
    <div className="home-page">
      <div className="search-ctn">
        <SearchBar />
        <span className="instructions">Search for a molecule, gene, variant, or combination</span>
      </div>

      <div className="container row mb-4">
        <div className="col-1" />
        <div className="col-md-6 alert alert-info mr-md-2 tour-gsi">
          <h4>Want Personalized PGx Recommendations?</h4>
          <div className="promo">
            <div className="promo__icon">
              <Link href="/genotype" title="Genotype Selection Interface">
                <Svg src={gsiIcon} ariaHidden={true} />
              </Link>
            </div>
            <div>
              Try the <Link href="/genotype">GSI (Genotype Selection Interface)</Link> to access and compare
              pharmacogenomic prescribing information from CPIC, DPWG, and FDA based on the genotypes you enter.
            </div>
          </div>
          <div className="promo mt-2">
            <div className="promo__icon promo__icon--right promo__icon--app">
              <Link href="https://ddrx.pharmgkb.org" title="DNA-Driven Prescribing">
                <Svg src={ddrxIcon} ariaHidden={true} />
              </Link>
            </div>
            <div>
              Try <Link href="https://ddrx.pharmgkb.org">DDRx (DNA-Driven Prescribing)</Link> to access the
              same prescribing information with a focus on actionability in a mobile-centric web app.
            </div>
          </div>
        </div>
        <div className="col-md-4 alert alert-info ml-md-2 tour-pediatrics">
          <h4>Interested in Pediatric Pharmacogenomics?</h4>
          <div className="promo">
            <div className="promo__icon promo__icon--pediatric">
              <Link href="/page/PedsIntro" title="Intro to Pediatric PGx">
                <PediatricIcon />
              </Link>
            </div>
            <div>
              Read about pediatrics on PharmGKB through the <Link href="/pediatric/dashboard">Pediatric Dashboard</Link>.
              Turn on the Pediatric Focus (using the Focus menu above) to highlight available pediatric information.
              See <Link href="/page/PedsIntro">Pediatric Help</Link> for more information.
            </div>
          </div>
        </div>
      </div>

      <ResourceCounts
        id="resources"
        pgkbPathways={resourceStats?.pgkbPathways?.count}
        dosingGuidelines={resourceStats?.dosing?.count}
        drugLabels={resourceStats?.labels?.count}
        fdaDrugLabels={resourceStats?.fdaLabels?.count}
      />

      <div className="info-hero info-hero--animated">
        <Svg className="animated-line" src={lineMiddleImage} ariaHidden={true} />

        <div className="background-images">
          <Svg className="chemical" src={chemicalBackgroundImage} ariaHidden={true} />
          <Svg className="dosing-guideline" src={guidelineBackgroundImage} ariaHidden={true} />
          <Svg className="drug-label" src={labelBackgroundImage} ariaHidden={true} />
          <Svg className="gene" src={geneBackgroundImage} ariaHidden={true} />
          <Svg className="literature" src={literatureBackgroundImage} ariaHidden={true} />
        </div>

        <div className="container">
          <div className="row about-ctn">
            <div className="col-md-6">
              <div className="what-is tour-moreinfo">
                <h3>What Is<br />Pharmaco<span className="genomics">genomics</span>?</h3>
                <p className="description">The study of the relationship<br />between genetic variations and how<br /> our body responds to medications.</p>
                <p><Button href="/whatIsPharmacogenomics" aria-label="What is Pharmacogenomics?" className="btn-success btn-lg">Pretty cool right? Tell me more...</Button></p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="about tour-learnpgkb">
                <h3>
                  Pharmaco<span className="genomics">genomics</span>.<br />
                  Knowledge.<br />
                  Implementation.
                </h3>
                <p className="description"><b>PharmGKB</b> is a comprehensive resource that<br />curates knowledge about the impact of genetic<br />variation on drug response for clinicians<br />and researchers.</p>
                <p><Button href="/whatIsPharmgkb" aria-label="What is PharmGKB?" className="btn-success btn-lg">Learn more about PharmGKB</Button></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnnotationsList
        dosingGuidelines={resourceStats?.dosing?.count}
        drugLabels={resourceStats?.labels?.count}
        fdaDrugLabels={resourceStats?.fdaLabels?.count}
        clinAnns={resourceStats?.clinAnns?.count}
        pgkbPathways={resourceStats?.pgkbPathways?.count}
        vipGenes={resourceStats?.vipGene?.count}
        basicVarAnns={resourceStats?.basicVarAnno?.count}
        drugsWithVarAnns={resourceStats?.drugsWithVariantAnnotations?.count}
      />

      <div className="row justify-content-center citation">
        <div className="col-1 align-self-center citation__logo">
          <InfoIcon size="2x" />
        </div>
        <div className="col-11 citation__text">
          PharmGKB data are under a Creative Commons license.  More details are in
          our <a href="/page/dataUsagePolicy">Data Usage Policy</a>.
          Please <a href="/page/citingPharmgkb">cite PharmGKB</a> if you use our information or
          images.
        </div>
      </div>

      <div className="line-cap line-cap-top line-cap--resources"><Svg src={lineCapImage} ariaHidden={true} /></div>
      <div className="line-cap line-cap-btm line-cap--resources"><Svg src={lineCapImage} ariaHidden={true} /></div>

      <div className="container home-section">
        <h3 className="section-heading resources">Resources</h3>
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="resource-center"><a href="/page/cancerPgx" className="btn btn-info btn-lg">Cancer Pharmacogenetics</a></div>
            <div className="resource-center"><a href="/page/DrugUseAndAddiction" className="btn btn-info btn-lg">PGx in Drug Use and Addiction</a></div>
            <div className="resource-center"><a href="/page/COVID" className="btn btn-info btn-lg">Therapeutic Resource for COVID-19</a></div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="resource-center"><a href="/page/pgxGeneRef" className="btn btn-info btn-lg">Gene Information Tables</a></div>
            <div className="resource-center"><a href="/fdaPgxAssociations" className="btn btn-info btn-lg">FDA PGx Associations</a></div>
            <div className="resource-center"><a href="/prescribingInfo" className="btn btn-info btn-lg">Drugs with Prescribing Information</a></div>
            <div className="resource-center"><a href="/ampAllelesToTest" className="btn btn-info btn-lg">AMP Recommendations</a></div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="resource-center"><a href="/page/biogeographicalGroups" className="btn btn-info btn-lg">Biogeographical Groups for PGx</a></div>
            <div className="resource-center"><a href="/page/curationTips" className="btn btn-info btn-lg">PGx Publication Tips</a></div>
            <div className="resource-center"><a href="/page/educationalResources" className="btn btn-info btn-lg">Educational Resources</a></div>
          </div>
        </div>
      </div>

      <div className="line-cap line-cap-top line-cap--news"><Svg src={lineCapImage} ariaHidden={true} /></div>
      <div className="line-cap line-cap-btm line-cap--news"><Svg src={lineCapImage} ariaHidden={true} /></div>
      <LatestNews />

      <div className="line-cap line-cap-top line-cap--partners"><Svg src={lineCapImage} ariaHidden={true} /></div>
      <div className="line-cap line-cap-btm line-cap--partners"><Svg src={lineCapImage} ariaHidden={true} /></div>
      <Partners />

    </div>
  );
}
