import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {forEach} from 'lodash';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

import Button from 'components/Button';
import ApproveAndDiffButtons from 'components/Button/ApproveAndDiff';
import EditButton from 'components/Button/Edit';
import {baseControlsColumn} from 'components/Table/Cell/Controls';
import MultiSelectFilter, {multiselectOrFilter} from 'components/Table/Filter/MultiSelect';
import TextFilter from 'components/Table/Filter/Text';
import SimpleTable from 'components/Table/Simple';
import {fixedColumnStyle, flexColumnStyle} from 'components/Table/columnHelpers';
import useEditContext from 'components/edit/EditContext';
import EditControls from 'components/edit/EditControls';
import FocusIcon from 'components/icons/Focus';
import InfoLink from 'components/links/Info';
import {getFontSize, getTextWidthInEm} from 'utils/browserUtils';


const propTypes = {
  objId: PropTypes.string,
  haplotypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  alleleType: PropTypes.string,
};
/**
 * Renders list of haplotypes for haplotypes tab.
 */
export default function HaplotypeTabList({objId, haplotypes = [], alleleType = 'Named Allele'}) {
  const editContext = useEditContext();
  
  const updatedFilters = useMemo(() => [], [editContext.isEditMode]);

  let hasFunctionTerm = false;
  let hasPharmVarId = false;
  let hasAmpTier = false;
  for (let x = 0; x < haplotypes.length; x += 1) {
    if (haplotypes[x].pharmVarId) {
      hasPharmVarId = true;
    }
    if (haplotypes[x].ampTier) {
      hasAmpTier = true;
    }
    if (haplotypes[x].functionTerm) {
      hasFunctionTerm = true;
    }
  }

  let maxAlleleName = '';
  forEach(haplotypes, (haplotype) => {
    maxAlleleName = haplotype.name.length > maxAlleleName.length ? haplotype.name : maxAlleleName;
  });

  const fontSize = getFontSize();
  const defaultAlleleNameWidth = 100;
  const maxNameWidth = Math.min(getTextWidthInEm(maxAlleleName, fontSize) * 16, 240);
  const alleleNameWidth = maxNameWidth < defaultAlleleNameWidth ? defaultAlleleNameWidth : maxNameWidth;

  const renderControlCell = ({row}) => {
    const {id, approved, published, geneId} = row.original;
    return (
      <EditControls className="editControls--inline">
        <Button href={`/haplotype/${id}`} newTab={true} iconOnly={true} className="btn-primary" title="View details">
          <FocusIcon />
        </Button>
        <EditButton href={`/edit/haplotypes/${objId ?? geneId}`} newTab={true} iconOnly={true}>edit</EditButton>
        <ApproveAndDiffButtons iconOnly={true} objCls="Haplotype" objId={id} approved={approved} published={published} />
        <span className="indicator">{!published && <FontAwesomeIcon icon="eye-slash" />}</span>
      </EditControls>
    );
  };
  
  const renderPharmVarIdCell = ({row}) => {
    const {pharmVarId, pharmVarUrl} = row.original;
    return <a href={pharmVarUrl}>{pharmVarId}</a>;
  };
  
  const columns = [];
  if (editContext.isEditMode) {
    // noinspection JSCheckFunctionSignatures
    columns.push({
      ...baseControlsColumn,
      Cell: renderControlCell,
      ...fixedColumnStyle(190),
    });
  }
  
  columns.push({
    id: 'namedAllele',
    Header: alleleType,
    accessor: 'name',
    Cell: NamedAlleleCell,
    Filter: TextFilter,
    sortable: true,
    ...flexColumnStyle({width: alleleNameWidth}),
  });

  if (hasFunctionTerm) {
    // noinspection JSCheckFunctionSignatures
    columns.push({
      id: 'functionTerm',
      Header: (
        <>
          CPIC Function
          <InfoLink
            href="/page/cpicFuncPhen"
            tooltip="Learn more about CPIC allele functions"
            iconSize="sm"
          />
        </>
      ),
      accessor: 'functionTerm',
      Filter: MultiSelectFilter,
      filter: multiselectOrFilter,
      sortable: true,
      ...flexColumnStyle({width: '280'}),
    });
  }
  
  if (hasAmpTier) {
    // noinspection JSCheckFunctionSignatures
    columns.push({
      id: 'ampTier',
      Header: (
        <>
          AMP Tier
          <InfoLink
            href="/ampAllelesToTest"
            tooltip="Learn more about AMP tiers"
            iconClassName="icon--superscript"
            iconSize="sm"
          />
        </>
      ),
      accessor: 'ampTier',
      sortable: true,
      Filter: MultiSelectFilter,
      filter: multiselectOrFilter,
    });
  }
  
  if (hasPharmVarId) {
    // noinspection JSCheckFunctionSignatures
    columns.push({
      id: 'pharmVarId',
      Header: 'PharmVar Id',
      accessor: 'pharmVarId',
      Cell: renderPharmVarIdCell,
      Filter: TextFilter,
      sortable: true,
      ...fixedColumnStyle(170),
    });
  }

  return (
    <SimpleTable
      id={`haplotypeTabList-${editContext.isEditMode}`}
      columns={columns}
      data={haplotypes}
      className="reactTableFrame--nonFluid"
      updatedFilters={updatedFilters}
    />
  );
}
HaplotypeTabList.propTypes = propTypes;


/**
 * Render a table cell containing namedAllele
 *
 * @param {object} props - props container
 * @param {object} [props.row]
 * @param {string} [props.value]
 * @return {JSX.Element|null}
 */
function NamedAlleleCell({row, value}) {
  if (!value) {
    return null;
  }
  const {id} = row.original;
  return (
    <a href={`/haplotype/${id}`} target="_blank" rel="noopener noreferrer">{value}</a>
  );
}
NamedAlleleCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
};
