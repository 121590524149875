import {lowerFirst, map} from 'lodash';


/**
 * Converts an accession identifier object into a link.
 *
 * @param {object} accId
 * @return {React.ReactNode}
 */
export const renderAccIdLink = (accId) => {
  const text = accId.symbol ? accId.symbol : accId.name;
  return (<a href={`/${lowerFirst(accId.objCls)}/${accId.id}`}>{text}</a>);
};


export const renderLinks = (links, conjunction) => {
  if (!links || links.length === 0) {
    return '';
  } else if (links.length === 1) {
    return links[0];
  } else if (links.length === 2) {
    return <span>{links[0]} {conjunction} {links[1]}</span>;
  } else {
    const data = map(links.slice(0, links.length - 2), (i) => (<span>{i}, </span>));
    data.push(<span>{links[links.length - 2]} {conjunction} {links[links.length - 1]}</span>);
    return data;
  }
};
