import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import brandmarkImage from 'images/logo/brandmark.svg';
import wordmarkPediatricImage from 'images/logo/wordmark-pediatric-stacked.svg';
import wordmarkImage from 'images/logo/wordmark.svg';

import BugButton from 'components/Button/BugButton';
import LicenseIcon from 'components/icons/License';
import FeedbackButton from 'components/shared/FeedbackButton';
import Svg from 'components/shared/Svg';
import MedicalDisclaimer from 'components/templates/MedicalDisclaimer';
import useAppContext from 'conf/AppContext';
import usePediatricContext from 'context/Pediatric';

import './footer.scss';

/**
 * Footer that appears on every page of the site
 *
 * @return {node} footer nav element
 */
export default function Footer({}) {
  const appContext = useAppContext();
  const pediatricContext = usePediatricContext();
  const wordmark = pediatricContext.isPediatricMode
    ? <Svg className="pageFooter__wordmark pageFooter__wordmark--pediatric" src={wordmarkPediatricImage} ariaLabel="PharmGKB Pediatric" />
    : <Svg className="pageFooter__wordmark" src={wordmarkImage} ariaLabel="PharmGKB" />;
  const FooterButton = appContext.isPreview ? BugButton : FeedbackButton;
  const fullYear = new Date().getFullYear();

  return (
    <footer>
      <MedicalDisclaimer />
      <div className="pageFooter">
        <nav className="container" aria-label="Footer">
          <div className="row d-print-none">
            <div className="col-md-3 col-lg-3">
              <a href="/" className="brand" aria-label="PharmGKB logo">
                <Svg className="pageFooter__brandmark" src={brandmarkImage} ariaHidden={true} />
                {wordmark}
              </a>
            </div>

            <div className="col-md-3 col-lg-2">
              <a href="/about" className="pageFooter-link">About PharmGKB</a>
              <a id="faq" href="/page/faqs" className="pageFooter-link">FAQ</a>
              <a href="/page/policies" className="pageFooter-link">Policies</a>
            </div>
            <div className="col-md-3 col-lg-2">
              <a href="/downloads" className="pageFooter-link">Downloads</a>
              <a href="/page/citingPharmgkb" className="pageFooter-link">Citing</a>
            </div>
            <div className="col-md-3 col-lg-3">
              <a href="/acknowledgements" className="pageFooter-link">Acknowledgements</a>
              <a href="/page/collaborators" className="pageFooter-link">Collaborators</a>
            </div>

            <div className="col-md-3 col-lg-2 pageFooter__social">
              <a
                href="https://www.youtube.com/channel/UCnYHYK_5HD1Lt2N_B4FsTYQ"
                title="PharmGKB @ YouTube"
                className="fa-layers fa-fw"
              >
                <FontAwesomeIcon icon="square" size="2x" className="icon_bg" />
                <FontAwesomeIcon icon={['fab', 'youtube']} size="lg" color="#FF0000" />
                <span className="screen-reader-text">PharmGKB @ YouTube</span>
              </a>
              <a
                href="https://www.facebook.com/PharmGKB"
                title="PharmGKB @ Facebook"
                className="fa-layers fa-fw"
              >
                <FontAwesomeIcon icon="square" size="2x" className="icon_bg" />
                <FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" color="#1778F3" />
                <span className="screen-reader-text">PharmGKB @ Facebook</span>
              </a>
              <a
                href="https://www.linkedin.com/company/pharmgkb"
                title="PharmGKB @ LinkedIn"
                className="fa-layers fa-fw"
              >
                <FontAwesomeIcon icon="square" size="2x" className="icon_bg" />
                <FontAwesomeIcon icon={['fab', 'linkedin-in']} size="lg" color="#0966C2" />
                <span className="screen-reader-text">PharmGKB @ LinkedIn</span>
              </a>
              <a
                href="https://blog.clinpgx.org"
                title="ClinPGx Blog"
                className="fa-layers fa-fw"
              >
                <FontAwesomeIcon icon="square" size="2x" className="icon_bg" />
                <FontAwesomeIcon icon="rss" size="lg" color="#ee802f" />
                <span className="screen-reader-text">ClinPGx Blog</span>
              </a>
            </div>
          </div>
          <div className="colophon">
            <div className="colophon__content">
              <p>PharmGKB<sup>&reg;</sup> is a registered trademark of HHS and is financially supported by NIH/NHGRI/NICHD/NIDA. It is managed at Stanford University (U24 HG010615). &copy;2001-{fullYear} PharmGKB.</p>
              <p>PharmGKB<sup>&reg;</sup> data are subject to the Creative Commons Attribution-ShareAlike 4.0 license. <LicenseIcon compact={true} /></p>
            </div>
          </div>
        </nav>
      </div>
      <FooterButton />
    </footer>
  );
}
