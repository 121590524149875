import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {find, isString, isNumber, map, size} from 'lodash';
import numeral from 'numeraljs';

import InlinePage from 'components/Page/Inline';
import SimpleTable from 'components/Table/Simple';
import {fixedColumnStyle, flexColumnStyle} from 'components/Table/columnHelpers';
import InfoLink from 'components/links/Info';
import HtmlContainer from 'components/shared/html_container';

import './index.scss';

const ALL_POPULATIONS = 'All populations';

/**
 * Table for displaying haplotype frequencies
 *
 * @param {object} props - props container
 * @param {string} props.source - the source enum value of frequency data
 * @param {object} props.data - frequency data
 * @param {string} props.alleleName - allele name to use in column header
 */
export default function HaplotypeFrequencyTable({data = {}, source, alleleName}) {
  const {notes, populations, label} = data;

  if (size(populations) === 0) {
    return <p className="mt-3 text-muted font-italic">No frequency data available from {label}</p>;
  }

  const hasAllPop = find(populations, (p) => p.population === ALL_POPULATIONS);

  const tableData = populations.map((p) => ({...p, ...p.bases[0]}));
  const calculateTotal = (pop) => {
    if (pop.total) {
      return pop.total;
    } else {
      return '-';
    }
  };

  return (
    <div>
      <InlinePage id={`freq${source}Header`} className="mt-2 mb-2" />
      {map(notes, (n) => <div className="alert alert-info"><HtmlContainer html={n} key={n} /></div>)}
      <SimpleTable
        columns={[
          {
            id: 'population',
            accessor: 'population',
            Cell: hasAllPop ? BranchingPopulationCell : NonBranchingPopulationCell,
            ...fixedColumnStyle(300),
            Header: <>Population <InfoLink href="/page/populationMappings" tooltip="Population Definitions" newTab={true} /></>,
          },
          {
            id: 'size',
            Header: `${alleleName} Observed`,
            accessor: 'size',
            Cell: SizeCell,
            ...flexColumnStyle({width: 140, grow: 1, shrink: 0}),
            sortable: true,
            className: 'text-right fixed-width-font',
          },
          {
            id: 'total',
            Header: 'Alleles Total',
            accessor: (row) => (row.freq >= 0 ? calculateTotal(row) : '-'),
            Cell: SizeCell,
            ...flexColumnStyle({width: 140, grow: 0, shrink: 1}),
            sortable: true,
            className: 'text-right fixed-width-font',
          },
          {
            id: 'freq',
            accessor: 'freq',
            Header: 'Frequency',
            Cell: FreqCell,
            ...flexColumnStyle({width: 140, grow: 0, shrink: 1}),
            sortable: true,
            sortType: sortFrequencies,
            className: 'text-right fixed-width-font',
          },
        ]}
        data={tableData.sort(comparePops)}
      />
    </div>
  );
}

function BranchingPopulationCell({value}) {
  if (value === ALL_POPULATIONS) {
    return value;
  } else {
    return <span><FontAwesomeIcon icon={['far', 'ellipsis-v']} /> <span className="ml-3">{value}</span></span>;
  }
}

function NonBranchingPopulationCell({value}) {
  return value;
}

function SizeCell({value}) {
  if (!isNumber(value)) {
    return '-';
  } else {
    return value;
  }
}

function FreqCell({value}) {
  if (isNumber(value)) {
    return numeral(value).format('0.00%');
  } else {
    return '-';
  }
}

function sortFrequencies(rowA, rowB, columnId) {
  const a = rowA.values[columnId];
  const b = rowB.values[columnId];

  if (!isNumber(a) && !a) {
    if (!isNumber(b) && !b) {
      return 0;
    }
    return 1;
  } else if (!isNumber(b) && !b) {
    return -1;
  }

  if (isString(a) && isString(b)) {
    return 0;
  } else if (isString(a)) {
    return -1;
  } else if (isString(b)) {
    return 1;
  } else {
    if (a > b) {
      return -1;
    } else if (a < b) {
      return 1;
    } else {
      return 0;
    }
  }
}

function comparePops(a, b) {
  const aPop = a.population;
  const bPop = b.population;

  // list all pops first
  if (aPop === ALL_POPULATIONS) {
    return -1;
  }
  if (bPop === ALL_POPULATIONS) {
    return 1;
  }
  // list other last
  if (aPop === 'Other') {
    return 1;
  }
  if (bPop === 'Other') {
    return -1;
  }

  if (aPop > bPop) {
    return 1;
  } else if (aPop < bPop) {
    return -1;
  } else {
    return 0;
  }
}
