const TourSteps = {

  variantAnnotation: [
    {
      intro: 'This is an example of one of PharmGKB&apos;s variant annotation' +
      ' pages.',
      element: '.resource-header-item',
      position: 'bottom',
    },
    {
      intro: 'All variant annotations have a single summary sentence that describes the' +
      ' association of the variant with one or more drugs, based on findings from the publication.',
      element: '.tour-variant-annotation-title',
      position: 'bottom',
    },
    {
      intro: 'If more description of the findings is necessary, additional details are written' +
      ' below the summary sentence.',
      element: '.tour-variant-ann-description',
      position: 'bottom',
    },
    {
      intro: 'This is the original publication that the variant annotation is written on.',
      element: '.tour-variant-ann-publication',
      position: 'right',
    },
    {
      intro: 'Key study parameters are provided, such as…',
      element: '.tour-variant-ann-study-params-header',
      position: 'right',
    },
    {
      intro: 'How many patients were studied…',
      element: '.tour-study-size',
      position: 'right',
    },
    {
      intro: 'The frequency of the allele of interest in the patients studied…',
      element: '.tour-study-frequency',
      position: 'right',
    },
    {
      intro: 'Any results from statistical analyses…',
      element: '.tour-p-value',
      position: 'right',
    },
    {
      intro: 'And any important characteristics about the group of people being studied.',
      element: '.tour-pop-char',
      position: 'right',
    },
    {
      intro: 'If the authors undertake multiple analyses, multiple study parameters can be' +
      ' created.',
      element: '.tour-variant-ann-study-params',
      position: 'right',
    },
    {
      intro: '<p>This second study parameter was created because the authors undertook two' +
      ' analyses.</p><br/><p> The first analysis was comparing the frequency of *58:01 in' +
      ' patients with allopurinol toxicity against allopurinol-tolerant patients; this data was' +
      ' captured in the first study parameter.</p><br/><p> The second analysis was comparing' +
      ' against random, healthy volunteers; this data is captured in this second study' +
      ' parameter.</p>',
      element: '.tour-second-study-parameter',
      position: 'top',
    },
  ],

  clinicalAnnotation: [
    {
      intro: 'This is a PharmGKB clinical annotation page.',
      element: '.resource-header-item',
      position: 'bottom',
    },
    {
      intro: 'Every clinical annotation is assigned a level of evidence based on the amount and' +
      ' quality of the literature evidence. <span><a href="/page/clinAnnLevels">Find out more' +
      ' about the levels of evidence for our clinical annotations</a></span>.',
      element: '.tour-clinical-ann-evidence-level',
      position: 'right',
    },
    {
      intro: '<p>"Phenotype Category" describes the content of the clinical annotation, for example, does it' +
      ' discuss an adverse reaction ("toxicity")? How well the drug works ("efficacy")?</p>' +
      '<br/><p> This annotation discusses how patients with a particular HLA-B genotype can' +
      ' develop severe skin reactions so the annotation is classified as "toxicity".</p>',
      element: '.tour-clinical-ann-type',
      position: 'right',
    },
    {
      intro: '<p>If the clinical annotation contains information relevant to a specialty population, such as' +
        ' pediatrics, a tag is displayed here. <a href="/pediatricTags" target="_blank">Learn more about our' +
        ' pediatric tag</a>.</p>',
      element: '.tour-specialty-population',
      position: 'right',
    },
    {
      intro: '<p>This section describes the association between the variant and drug of' +
      ' interest. The associations discussed are always written for genotypes or haplotypes' +
      ' <strong>as compared</strong> to other genotypes or haplotypes.</p>',
      element: '.tour-allele-phenotypes-table',
      position: 'bottom',
    },
    {
      intro: '<p>Clinical annotations are assigned a <a href="/page/clinAnnScoring" target="_blank">score</a> as' +
        ' part of the process of assigning a <a target="_blank" href="/page/clinAnnLevels">Level of Evidence</a>.' +
        ' The annotation’s total score and other details are displayed here.</p>',
      element: '.tour-clinical-ann-score',
      position: 'bottom',
    },
    {
      intro: '<p>All the evidence that supports the association is listed here.' +
      ' Evidence can be one or more <span><a href="/variantAnnotations" target="_blank">variant annotations</a> and' +
        ' supporting <a href="/guidelineAnnotations" target="_blank">clinical guideline annotations</a> or' +
        ' <a href="/labelAnnotations" target="_blank">drug label annotations</a>.</span>.</p>',
      element: '.tour-clinical-ann-evidence',
      position: 'top',
    },
  ],

  pathway: [
    {
      intro: 'This is a PharmGKB pathway page.',
      element: '.resource-header-item',
      position: 'bottom',
    },
    {
      intro: 'From this side bar, the components tab shows the literature evidence for every' +
      ' connection on the pathway.',
      element: '.sideNavMenu__desktop__container .sideNavMenu__item:nth-of-type(2)',
      position: 'right',
    },
    {
      intro: 'The downloads tab is where you can download a PDF version of the pathway ' +
        '(among other formats), as well as the data in the pathway for scientific analyses.',
      element: '.sideNavMenu__desktop__container .sideNavMenu__item:nth-of-type(6)',
      position: 'right',
    },
    {
      intro: 'A one-sentence description of the pathway.',
      element: '#pathway-summary',
      position: 'right',
    },
    {
      intro: 'The pathway image. On some pathways, you can click on a gene, drug or pathway' +
      ' object in the image and be taken to the page for that object on PharmGKB.',
      element: '.pathwayDiagram > img',
      position: 'left',
    },
    {
      intro: 'Pathways on PharmGKB are accompanied by descriptive text.',
      element: '#pathway-description h3',
      position: 'right',
    },
    {
      intro: 'The appropriate reference for citing the pathway can be found here.',
      element: '#pathway-citation h3',
      position: 'right',
    },
  ],

  guidelineAnnotation: [
    {
      intro: 'This is one of PharmGKB&apos;s clinical guideline annotation pages.',
      element: '.resource-header-item',
      position: 'bottom',
    },
    {
      intro: 'Here you can find a short summary of the clinical guideline annotation.',
      element: '#tour-guideline-summary',
      position: 'top',
    },
    {
      intro: 'Already know your genetic information for this gene? Enter it here and get' +
      ' dosing recommendations.',
      element: '#tour-annotation-picker',
      position: 'bottom',
    },
    {
      intro: '<p>Details on the guidelines, including links to PDF copies of all guidelines and' +
      ' supplements can be found here.</p><p>The main dosing recommendations are also' +
      ' always provided in a table format at the bottom of the page.</p>',
      element: '#tour-guideline-annotation h3',
      position: 'top',
    },

    {
      intro: 'Detailed citation information for the guideline can be found here.',
      element: '.sideNavMenu__item--publications',
      position: 'right',
    },
  ],

  vip: [
    {
      intro: 'This is one of our VIP pages, focusing on the pharmacogene CYP2C9.',
      element: '.resource-header-item',
      position: 'bottom',
    },
    {
      intro: 'This is the main VIP text. Information on inducers/inhibitors, structure' +
      ' and adverse drug reactions, amongst other things, can be found here.',
      element: '#tour-vip-main-text',
      position: 'right',
      scrollTo: 'tooltip',
    },
    {
      intro: 'This tag indicates that CYP2C9 is a Tier 1 VIP, meaning that there is a ' +
        'substantial amount of evidence to support its status as an important pharmacogene. ' +
        'Learn more about our VIP Tiers by clicking the info button on the tag.',
      element: '.vipTierTag',
      position: 'bottom',
    },
    {
      intro: 'Key literature related to the VIP can be found by clicking here.',
      element: '.sideNavMenu__desktop__container .sideNavMenu__item:nth-of-type(2)',
      position: 'right',
    },
    {
      intro: 'A list of drugs or diseases that the gene is associated with, or any PharmGKB' +
      ' pathways that the gene appears in, can be found by clicking here.',
      element: '.sideNavMenu__desktop__container .sideNavMenu__item:nth-of-type(3)',
      position: 'right',
    },
    {
      intro: 'If the VIP was published, the citation information is provided here.',
      element: '#vip-citation h3',
      position: 'right',
    },
  ],

  labelAnnotation: [
    {
      intro: 'This is a PharmGKB annotation for a drug label approved by the U.S. Food and Drug' +
      ' Administration (FDA).',
      element: '.resource-header-item',
      position: 'bottom',
    },
    {
      intro: '<p>Every drug label annotation has a tag describing the level of PGx information' +
        ' provided.' +
        ' <br /><span><a href="/page/drugLabelLegend">Learn more about our drug label' +
        ' annotation evidence levels</a></span>.</p>',
      element: '.laLevelTag',
      position: 'right',
    },
    {
      intro: '<p>This tag means that this drug label appears on the U.S. FDA&apos;s <span><a ' +
        'target="_blank" rel="noopener noreferrer" href="https://www.fda.gov/Drugs/' +
        'ScienceResearch/ResearchAreas/Pharmacogenetics/ucm083378.htm">list of drug labels that ' +
        'contain pharmacogenomic information</a></span>.</p>',
      element: '.fdaBiomarkerTag',
      position: 'right',
    },
    {
      intro: '<p>We also tag labels which provide specific guidance for patients based on ' +
        'their genetic information, and further highlight those labels that suggest changes ' +
        'to drug dosing or use of alternate drugs.</p>',
      element: '.tour-tags',
      position: 'right',
    },
    {
      intro: '<p>A short summary of the pharmacogenomic info in the label is always' +
        ' provided.</p><br/><p>Because the label says certain patients should be genotyped' +
        ' prior to treatment, this label received the "Testing Required" evidence level.</p>',
      element: '#label-summary',
      position: 'right',
    },
    {
      intro: '<p>Excerpts from the label where pharmacogenomic information is discussed are always' +
        ' provided.</p><br/><p>Excerpts containing pharmacogenomic information relevant to ' +
        'prescribing of the drug are found in the Prescribing section…</p>',
      element: '#prescribing',
      position: 'right',
    },
    {
      intro: '<p>…while other label excerpts with pharmacogenomic information ' +
        'are shown in the main annotation. </p><br/><p>A link to download a PDF version of the drug' +
      ' label with PGx information highlighted is found at the end of the annotation.</p>',
      element: '#label-annotation h3',
      position: 'right',
    },
    {
      intro: '<p>When available, information about this label from the FDA Table of ' +
        'Pharmacogenetic Associations is shown here.</p>',
      element: '#fda-pgx-associations',
      position: 'right',
    },
  ],

  home: [
    {
      intro: 'Know what you&apos;re looking for? Search here for medications, genes, and/or a' +
      ' particular genetic variant, and see all our information on that item.',
      element: '.search-ctn',
      position: 'bottom',
    },
    {
      intro: 'Don&apos;t know what you&apos;re looking for? Want to find out more about' +
      ' pharmacogenomics? Start here!',
      element: '.tour-moreinfo',
      position: 'bottom',
    },
    {
      intro: 'Familiar with pharmacogenomics, but want to know about PharmGKB? Click here.',
      element: '.tour-learnpgkb',
      position: 'bottom',
    },
    {
      intro: 'Use these boxes to browse our content on…',
      element: '.resource-counts',
      position: 'bottom',
    },
    {
      intro: 'Guidelines on how to adjust medications based on genetic information…',
      element: '.dosing',
      position: 'bottom',
    },
    {
      intro: 'Or drug labels from the US and abroad that contain pharmacogenetic information.',
      element: '.labels',
      position: 'bottom',
    },
    {
      intro: 'Our FDA drug label annotations also contain information taken from the ' +
        'FDA Table of Pharmacogenomic Biomarkers.',
      element: '.tour-fdaLabels',
      position: 'bottom',
    },
    {
      intro: 'PharmGKB pathways show how important drugs are broken down, or how they work.',
      element: '.pathways',
      position: 'bottom',
    },
    {
      intro: 'Use our Genotype Selection Interface to see relevant information ' +
        'from multiple sources for a specific genotype.',
      element: '.tour-gsi',
      position: 'bottom',
    },
    {
      intro: 'Interested in pediatrics? Use the Focus button to make relevant annotations ' +
        'more prominent across the site.',
      element: '.tour-pediatrics',
      position: 'bottom',
    },

    {
      intro: 'You can also browse our reviews on genes important to pharmacogenomics.',
      element: '#vips',
      position: 'top',
    },
    {
      intro: 'Or read more about our clinical annotations…',
      element: '#clinann',
      position: 'bottom',
    },
    {
      intro: '…or variant annotations.',
      element: '#varann',
      position: 'bottom',
    },
    {
      intro: 'Check out the latest on pharmacogenomics and PharmGKB, as sourced from our blog.',
      element: '.news',
      position: 'bottom',
    },
    {
      intro: 'Read details about PharmGKB, and get answers to your questions from the FAQ…',
      element: '#faq',
      position: 'top',
      highlightClass: 'helperLayer_blue',
    },
    {
      intro: '<p>…or by emailing us directly from any page on the site.</p><br /><p>If you' +
      ' want to retake this tour at any time, you can find it by clicking the link in the' +
      ' Help menu at the top of the page.</p>',
      element: '.feedbackButton',
      position: 'left',
      tooltipClass: 'homepage--final-step',
    },
  ],

  chemical: [
    {
      intro: 'This is one of PharmGKB&apos;s drug pages. Detailed information on the drug is' +
      ' provided here.',
      element: '.resource-header-items',
      position: 'bottom',
      highlightClass: 'helperLayer_blue',
    },
    {
      intro: 'Any information on how to adjust drug doses based on genetic information can' +
      ' be found here, or under the "Prescribing Info" tab on the left.',
      element: '.resourceCounts a:first-child',
      position: 'right',
    },
    {
      intro: 'If the drug labels for this medication contain genetic information, they will be' +
      ' provided here.',
      element: '.resourceCounts a:nth-child(2)',
      position: 'right',
    },
    {
      intro: 'You can also explore clinical annotations on drugs from here, and variant' +
      ' annotations from the tab on the left.',
      element: '.resourceCounts a:nth-child(3)',
      position: 'right',
    },
    {
      intro: 'Any available illustrations showing the metabolism and mechanism of action of the' +
      ' drug can be found here.',
      element: '.resourceCounts a:nth-child(4)',
      position: 'right',
    },
    {
      intro: 'Every gene, drug and variant page on PharmGKB has the same layout, but not' +
      ' necessarily the same content.',
      element: '.fact-section-content',
      position: 'bottom',
    },
    {
      intro: 'This label indicates that this drug has annotations relevant to pediatric patients. ' +
        'Turn on Pediatric Focus in the top right corner to learn more.',
      element: '.tour-pediatric',
      position: 'bottom',
    },
  ],

  chemicalNoPediatric: [
    {
      intro: 'This is one of PharmGKB&apos;s drug pages. Detailed information on the drug is' +
        ' provided here.',
      element: '.resource-header-items',
      position: 'bottom',
      highlightClass: 'helperLayer_blue',
    },
    {
      intro: 'Any information on how to adjust drug doses based on genetic information can' +
        ' be found here, or under the "Prescribing Info" tab on the left.',
      element: '.resourceCounts a:first-child',
      position: 'right',
    },
    {
      intro: 'If the drug labels for this medication contain genetic information, they will be' +
        ' provided here.',
      element: '.resourceCounts a:nth-child(2)',
      position: 'right',
    },
    {
      intro: 'You can also explore clinical annotations on drugs from here, and variant' +
        ' annotations from the tab on the left.',
      element: '.resourceCounts a:nth-child(3)',
      position: 'right',
    },
    {
      intro: 'Any available illustrations showing the metabolism and mechanism of action of the' +
        ' drug can be found here.',
      element: '.resourceCounts a:nth-child(4)',
      position: 'right',
    },
    {
      intro: 'Every gene, drug and variant page on PharmGKB has the same layout, but not' +
        ' necessarily the same content.',
      element: '.fact-section-content',
      position: 'bottom',
    },
  ],
};

export default TourSteps;
