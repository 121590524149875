import {map} from 'lodash';

import Link from 'components/links/Link';
import DrugResultGridGenotypeResult from 'pages/genotype/DrugResultGridGenotypeResult';

/**
 * A component that will display an annotation (usually based on a guideline annotation, label annotation, etc...) and
 * any genotype results (i.e. recommendations) that matched from that annotation.
 */
export default function DrugResultGridAnnotation({
  onOpenChange,
  recommendationAnnotations,
  genotypes,
  phenotypes,
  description,
  linkText,
  linkUrl,
  multipopulation,
  objCls,
  source,
}) {
  return (
    <div className="mb-2">
      <div>{description}</div>
      {map(recommendationAnnotations, (r) => (
        <DrugResultGridGenotypeResult
          key={`${r.id}`}
          phenotypes={phenotypes}
          onOpenChange={onOpenChange}
          multiPopulation={multipopulation}
          {...r}
          genotypes={genotypes}
          parentCls={objCls}
          parentSource={source}
        />
      ))}
      {linkText && linkUrl && <Link newTab={true} href={linkUrl}>{linkText}</Link>}
    </div>
  );
}
