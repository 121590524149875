import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Link from 'components/links/Link';
import useAppContext from 'conf/AppContext';


/**
 * Renders floating bug button
 *
 * @return {node} the bug node
 */
export default function BugButton() {
  const appContext = useAppContext();
  const url = encodeURI('https://github.com/PharmGKB/PharmGKB/issues/new?assignees=&labels=&template=bug_report.md&title=Website bug&body=Bug on ' + appContext.getCurrentUrl());
  return (
    <div className="feedbackButton d-none d-sm-block">
      <Link href={url} newTab={true} title="Submit bug" tabIndex={0}>
        <div className="feedbackButton__icon">
          <FontAwesomeIcon icon="bug" />
        </div>
      </Link>
    </div>
  );
}
