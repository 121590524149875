import PropTypes from 'prop-types';

import './TabIntro.scss';
import Button from 'components/Button';
import OnOffButton from 'components/Button/OnOff';
import InfoIcon from 'components/icons/Info';
import Link from 'components/links/Link';
import AlleleNote from 'components/shared/AlleleNote';
import usePediatricContext from 'context/Pediatric';


const propTypes = {
  id: PropTypes.string.isRequired,
  chart: PropTypes.node,
};
/**
 * Renders the intro blurb for the clinical annotation tab.
 */
export default function ClinicalAnnotationsTabIntro({id, chart}) {
  const pediatricContext = usePediatricContext();

  const pedFocusHandler = () => pediatricContext.togglePediatricMode('caTab');

  return (
    <>
      <div className="float-right" style={{width: '15em', fontSize: '.94em'}}>
        <OnOffButton
          label="Focus on Pediatrics"
          on={pediatricContext.isPediatricMode}
          stateful={false}
          actionHandler={pedFocusHandler}
          tabIndex={0}
        />
      </div>
      <h3>Clinical Annotations</h3>

      <div className="clearfix">
        {chart}
        <div className="island-bottom">
          <p>
            PharmGKB clinical annotations provide information about variant-drug pairs based primarily on variant
            annotations and incorporating variant-specific prescribing guidance from clinical guidelines and
            FDA-approved drug labels, when available. Curators manually review annotations and create genotype-based
            summaries describing the phenotypic impact of the variant. Each clinical annotation is assigned a {' '}
            <Link href="/page/clinAnnLevels">Level of Evidence</Link>, which is generally informed by the clinical
            annotation&apos;s <Link href="/page/clinAnnScoring">score</Link>.
          </p>
          <AlleleNote />
        </div>
      </div>

      <div className="caTabIntroHelp">
        <div>
          <Button className="btn-secondary" href="/clinicalAnnotations"><InfoIcon /> Read more about Clinical Annotations</Button>
        </div>
        <div><Button className="btn-secondary" href="/variantAnnotations"><InfoIcon /> Read more about Variant Annotations</Button></div>
      </div>
    </>
  );
}
ClinicalAnnotationsTabIntro.propTypes = propTypes;
