import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {forEach, map, toLower} from 'lodash';
import PropTypes from 'prop-types';

import AutomatedAnnLiterature from 'components/AutomatedAnn/Literature';
import Loading from 'components/Loading';
import InlinePage from 'components/Page/Inline';
import KyError from 'components/errors/KyError';
import DownloadLink from 'components/links/Download';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';
import {variantNameComparator} from 'utils/comparators';


export const AUTOMATED_ANNOTATION_TAB_TITLE = 'Automated Annotations';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  objCls: PropTypes.string.isRequired,
  name: PropTypes.string,
};

/**
 * Tab for automated annotations.
 */
export default function AutomatedAnnTab({id, objCls, name}) {
  const appContext = useAppContext();
  const params = {};
  if (objCls === 'literature') {
    params.literatureId = id;
  } else {
    params.ids = id;
  }

  const {response, error} = useGet('search/nlp', {
    searchParams: params,
    cacheResponse: true,
  });


  let count;
  let body = <Loading />;
  let downloadBtn;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (response) {
    const data = response.data.hits;
    if (data.length === 0) {
      count = <p className="empty">No automated annotations.</p>;
      body = '';
    } else {
      count = <p className="totalAnnotation">{response.data.hits.length} annotations</p>;
      body = renderData(id, objCls, name, data, appContext.isPreview);
      const downloadParams = {...params, format: 'tsv'};
      downloadBtn = (
        <DownloadLink href={`${appContext.apiUrl('/search/nlp')}?${new URLSearchParams(downloadParams).toString()}`}>
          Download Annotations
        </DownloadLink>
      );
    }
  }

  return (
    <section className="nlp-set">
      <h3>{AUTOMATED_ANNOTATION_TAB_TITLE}</h3>
      <InlinePage id="automatedAnnotationDescription" className="mb-4" editHint="Overview" />
      {count}
      {body}
      {downloadBtn}
    </section>
  );
}


function getArray(obj, key) {
  let data = obj[key];
  if (!data) {
    data = [];
    // eslint-disable-next-line no-param-reassign
    obj[key] = data;
  }
  return data;
}

function getMap(obj, key) {
  let data = obj[key];
  if (!data) {
    data = {};
    // eslint-disable-next-line no-param-reassign
    obj[key] = data;
  }
  return data;
}

function addVariation(data, doc) {
  if (doc.variationId) {
    getArray(data, doc.variationName).push(doc);
  } else {
    getArray(data, doc.variationInText).push(doc);
  }
}

function renderData(id, objCls, objName, data, isPreview) {
  const sortedData = {};
  forEach(data, (doc) => {
    switch (objCls) {
      case 'chemical':
        if (doc.variationInText) {
          addVariation(sortedData, doc);
        } else if (doc.geneSymbols) {
          getArray(sortedData, doc.geneSymbols[0]).push(doc);
        } else {
          getArray(sortedData, doc.geneInText).push(doc);
        }
        break;
      case 'variant':
      case 'haplotype':
        getArray(sortedData, doc.chemicalName).push(doc);
        break;
      case 'gene':
        addVariation(getMap(sortedData, doc.chemicalName), doc);
        break;
      default:
        // combination, literature
        getArray(getMap(sortedData, doc.variationName || doc.variationInText), doc.chemicalName)
          .push(doc);
        break;
    }
  });

  switch (objCls) {
    case 'chemical':
    case 'variant':
    case 'haplotype':
      return renderAssociationPair(sortedData, objCls, isPreview);
    default:
      // combination, gene, literature
      return map(Object.keys(sortedData).sort(variantNameComparator), (key) =>
        renderAssociationPair(sortedData[key], objCls, isPreview));
  }
}


function renderAssociationPair(data, objCls, isPreview) {
  const keys = Object.keys(data).sort(variantNameComparator);
  return map(keys, (key) => {
    const docs = data[key];
    let description;
    switch (objCls) {
      case 'chemical':
        description = <h4>{renderVariationLink(docs[0], isPreview)}:</h4>;
        break;
      case 'variant':
      case 'haplotype':
        description = <h4>{renderChemicalLink(docs[0], isPreview)}:</h4>;
        break;
      case 'gene':
        description = (
          <h4>
            {renderChemicalLink(docs[0], isPreview)} and {renderVariationLink(docs[0], isPreview)}:
          </h4>
        );
        break;
      default:
        // literature
        description = (
          <h4>
            {renderVariationLink(docs[0], isPreview)} and {renderChemicalLink(docs[0], isPreview)}:
          </h4>
        );
        break;
    }
    return (
      <div className="nlp" key={key}>
        {description}
        <AutomatedAnnLiterature docs={docs} objCls={objCls} />
      </div>
    );
  });
}

function renderObjectLink(id, type, name, inPgkb, isPreview) {
  if (id && inPgkb) {
    return <> <a href={`/${toLower(type)}/${id}`}>{name}</a></>;
  } else if (isPreview) {
    return <> {name}<sup><FontAwesomeIcon icon="unlink" className="text-danger" /></sup></>;
  } else {
    return name;
  }
}

function renderVariationLink({variationId, variationType, variationName, variationInText,
  variationInPharmgkb}, isPreview) {
  return renderObjectLink(variationId, variationType, variationName || variationInText,
    variationInPharmgkb, isPreview);
}

function renderChemicalLink({chemicalId, chemicalName, chemicalInPharmgkb}, isPreview) {
  return renderObjectLink(chemicalId, 'chemical', chemicalName, chemicalInPharmgkb, isPreview);
}


AutomatedAnnTab.propTypes = propTypes;
