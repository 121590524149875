import PropTypes from 'prop-types';
import {ReactElement, useEffect, useState} from 'react';

import NotFound from 'components/shared/error/not_found';
import useAppContext from 'conf/AppContext';

const propTypes = {
  id: PropTypes.string,
};
/**
 * Redirect Pmid to the related page.
 *
 * @param {object} props - props container
 * @param {string} props.id
 * @return {ReactElement}
 */
export default function RedirectPmid({id}) {
  const appContext = useAppContext();
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      try {
        const rez = await appContext.api.post('search', {
          json: {
            objCls: ['Literature'],
            query: `PMID:${id}`,
            field: 'synonyms',
          },
          parseJson: true,
        });
        if (mounted) {
          if (rez.data.total === 1) {
            appContext.redirect(rez.data.hits[0].url, true);
          } else {
            setError(<NotFound />);
          }
        }
      } catch (err) {
        if (mounted) {
          setError(<NotFound />);
        }
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadData();
    return () => {
      mounted = false;
    };
  }, [id]);

  return error;
}
RedirectPmid.propTypes = propTypes;
