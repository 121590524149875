import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {includes, map} from 'lodash';

import EditControls from 'components/edit/EditControls';
import Link from 'components/links/Link';
import PediatricTag from 'components/Tag/Pediatric';
import usePediatricContext from 'context/Pediatric';
import {renderMetadata, renderTitle} from 'components/Literature/Citation';


const propTypes = {
  docs: PropTypes.arrayOf(PropTypes.shape({
    documentId: PropTypes.string.isRequired,
    literatureId: PropTypes.number.isRequired,
    pmid: PropTypes.string.isRequired,
    literatureTitle: PropTypes.string.isRequired,
    journal: PropTypes.string,
    publicationYear: PropTypes.number,
    sentence: PropTypes.string.isRequired,
    geneIds: PropTypes.arrayOf(PropTypes.string),
    geneSymbols: PropTypes.arrayOf(PropTypes.string),
    geneInText: PropTypes.string,
    chemicalId: PropTypes.string,
    chemicalName: PropTypes.string,
    chemicalInText: PropTypes.string.isRequired,
    chemicalInPharmgkb: PropTypes.bool.isRequired,
    variationId: PropTypes.string,
    variationType: PropTypes.string,
    variationName: PropTypes.string,
    variationInText: PropTypes.string.isRequired,
    variationInPharmgkb: PropTypes.bool.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  showCitation: PropTypes.bool,
};

/**
 * Renders automated annotation sentences from literature.
 */
export default function AutomatedAnnLiterature({docs, showCitation = true}) {
  const pediatricContext = usePediatricContext();
  const lit = map(docs, ({
    documentId, literatureId, pmid, literatureTitle, journal, publicationYear, sentence,
    geneIds, geneSymbols, geneInText,
    chemicalId, chemicalName, chemicalInText, chemicalInPharmgkb,
    variationId, variationType, variationName, variationInText, variationInPharmgkb,
    tags,
  }) => {
    const searchWords = [];
    if (chemicalInText) {
      searchWords.push(chemicalInText);
    }
    if (variationInText) {
      if (variationType === 'Haplotype') {
        if (variationName) {
          searchWords.push(`${geneSymbols[0]}${variationInText}`);
          searchWords.push(`${geneSymbols[0]} ${variationInText}`);
        } else {
          searchWords.push(variationInText);
        }
      } else {
        searchWords.push(variationInText);
      }
    }
    if (geneInText) {
      searchWords.push(geneInText);
    }

    const litLink = showCitation
      ? renderCitation(literatureId, literatureTitle, journal, publicationYear, pmid, tags, pediatricContext.isPediatricMode)
      : renderPediatricBadge(tags, pediatricContext.isPediatricMode);

    return (
      <li key={documentId}>
        <blockquote>
          <Highlighter
            highlightClassName="highlight"
            searchWords={searchWords}
            highlightTag="span"
            textToHighlight={sentence}
            autoEscape={true}
          />
          {litLink}
        </blockquote>
      </li>
    );
  });

  return (
    <ol>
      {lit}
    </ol>
  );
}

function renderPediatricBadge(tags, isPediatricMode) {
  if (isPediatricMode && includes(tags, 'pediatric')) {
    return <> <PediatricTag /></>;
  }
  return '';
}

function renderPmidLink(literatureId, pmid) {
  // make sure we duplicate styling from links/LitXref
  return (
    <span className="litXrefLink">
      <span className="litXrefLink__label">PMID:</span>
      {
        literatureId
          ? <a href={`/literature/${literatureId}`}>{pmid}</a>
          : (
            <>
              <Link href={`https://www.ncbi.nlm.nih.gov/pubmed/${pmid}`}>{pmid}</Link>
              <EditControls className="editControls--inline"><sup><FontAwesomeIcon icon="unlink" className="text-danger" /></sup></EditControls>
            </>
          )
      }
    </span>
  );
}

function renderCitation(literatureId, literatureTitle, journal, publicationYear, pmid, tags, isPediatricMode) {
  return (
    <div className="automatedAnnotationLiterature">
      <small>
        {renderTitle('', literatureTitle, false)} {renderMetadata(journal, publicationYear)}
        {renderPmidLink(literatureId, pmid)}
        {renderPediatricBadge(tags, isPediatricMode)}
      </small>
    </div>
  );
}

AutomatedAnnLiterature.propTypes = propTypes;
