import {parseISO} from 'date-fns';
import {format as dateFormat} from 'date-fns/format';
import {diffWords} from 'diff';
import {get, keys, map, orderBy, toNumber, uniq} from 'lodash';
import {useState} from 'react';

import DiffWords from 'components/Diff/Words';
import Loading from 'components/Loading';
import KyError from 'components/errors/KyError';
import Form from 'components/form';
import FormCombobox from 'components/form/Combobox';
import FormSubmit from 'components/form/Submit';
import ReadableWidthTemplate from 'components/templates/ReadableWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';
import {textify} from 'pages/LabelApplicationDiff/utils';

/**
 * Component to let the user pick two versions of a label application and see the difference between them
 *
 * @param {object} props - props container
 * @param {string} props.applicationId - FDA application ID
 */
export default function LabelApplicationDiff({applicationId}) {
  const appContext = useAppContext();
  const {response, error} = useGet('data/LabelApplicationVersion', {searchParams: {applicationId}});
  const [diffs, setDiffs] = useState([]);
  const [contentError, setContentError] = useState(undefined);

  const handleSubmit = async (d) => {
    const lVersion = d.lVersion.value;
    const rVersion = d.rVersion.value;
    
    if (lVersion === rVersion) {
      setDiffs([]);
    } else {
      try {
        const lResponse = await appContext.api.get(
          'data/LabelApplicationVersion',
          {searchParams: {applicationId, view: 'max', fdaVersion: lVersion}, parseJson: true});
        const rResponse = await appContext.api.get(
          'data/LabelApplicationVersion',
          {searchParams: {applicationId, view: 'max', fdaVersion: rVersion}, parseJson: true});

        const lData = getLabelText(lResponse?.data);
        const rData = getLabelText(rResponse?.data);
        const rawKeys = [...keys(lData), ...keys(rData)];
        const uniqKeys = uniq(rawKeys);

        const diffByKeys = {};
        for (let i = 0; i < uniqKeys.length; i += 1) {
          const key = uniqKeys[i];
          const lText = textify(get(lData, key, ''));
          const rText = textify(get(rData, key, ''));
          
          if (lText === rText) {
            diffByKeys[key] = false;
          } else {
            diffByKeys[key] = diffWords(lText, rText);
          }
        }
        setDiffs(diffByKeys);
      } catch (err) {
        setContentError(err);
      }
    }
  };

  let body;
  if (error) {
    body = <KyError kyError={error} />;
  } else if (!response) {
    body = <Loading />;
  } else {
    const sortedVersions = orderBy(response.data, [(v) => toNumber(v.fdaVersion)], ['desc']);
    const versionOptions = map(
      sortedVersions,
      (v) => ({value: v.fdaVersion, label: v.fdaVersion + ' ' + dateFormat(parseISO(v.created), '(yyyy-MM-dd)')}));
    const lDefault = sortedVersions.length < 2 ? 0 : 1;
    const rDefault = 0;
    body = (
      <Form
        defaultValues={{lVersion: versionOptions[lDefault], rVersion: versionOptions[rDefault]}}
        onSubmit={handleSubmit}
      >
        <div className="form-row">
          <div className="col-6">
            <FormCombobox label="Left" name="lVersion" options={versionOptions} />
          </div>
          <div className="col-6">
            <FormCombobox label="Right" name="rVersion" options={versionOptions} />
          </div>
        </div>
        <FormSubmit>Show Difference</FormSubmit>
      </Form>
    );
  }
  return (
    <ReadableWidthTemplate title={`Diff for FDA Application ${applicationId}`}>
      {body}
      {contentError && <KyError kyError={contentError} />}
      {!contentError && (
        <div>
          {map(Object.keys(diffs), (k) => {
            const diff = diffs[k];
            let diffResults;
            if (!diff) {
              diffResults = <div className="text-muted font-italic ml-3">No diffs found</div>;
            } else {
              diffResults = <DiffWords diffs={diffs[k]} />;
            }

            return (
              <div key={k} className="mb-3">
                <h5>{k}</h5>
                {diffResults}
              </div>
            );
          })}
        </div>
      )}
    </ReadableWidthTemplate>
  );
}

function getLabelText(labelObj) {
  const subsetObj = get(labelObj, '[0].labelText.results[0]');
  if (subsetObj) {
    delete subsetObj.openfda;
  }
  return subsetObj;
}
