import {trim} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import Form from 'components/form';
import FormSubmit from 'components/form/Submit';
import FormText from 'components/form/Text';
import logger from 'conf/Logger';
import PasswordConfirmationField from 'pages/user/PasswordConfirmationField';
import PasswordStrength from 'pages/user/PasswordStrength';


const propTypes = {
  requiresCurrentPassword: PropTypes.bool,
  onSubmit: PropTypes.func,
};
export default function PasswordForm({requiresCurrentPassword = false, onSubmit}) {
  const [zxcvbn, setZxcvbn] = useState(null);

  useEffect(() => {
    let mounted = true;
    const loadModule = async () => {
      try {
        const module = await import(/* webpackChunkName: "zxcvbn" */'zxcvbn');
        if (mounted) {
          setZxcvbn(module);
        }
      } catch (error) {
        if (mounted) {
          logger.warn('Could not load zxcvbn', error);
        }
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    loadModule();
    return () => {
      mounted = false;
    };
  }, []);

  const saveButtonLabel = requiresCurrentPassword ? 'Change Password' : 'Save Password';

  return (
    <Form onSubmit={onSubmit}>
      {requiresCurrentPassword && (
        <FormText
          name="currentPassword"
          label="Current Password"
          type="password"
          autoComplete="password"
          required={true}
        />
      )}
      <FormText
        name="password"
        label="New Password"
        type="password"
        required={true}
        validation={{
          minLength: (v) => trim(v?.length) >= 12 || 'Password must be at least 12 characters.',
          pattern: (v) => v.trim() === v || 'Password cannot start or end with spaces.',
          strength: (v) => zxcvbn.default(v).score >= 3,
        }}
      />
      <PasswordStrength zxcvbn={zxcvbn} field="password" className="mb-3" />
      <PasswordConfirmationField name="passwordConfirmation" matchFieldName="password" />
      <FormSubmit showError={false}>{saveButtonLabel}</FormSubmit>
    </Form>
  );
}
PasswordForm.propTypes = propTypes;
