import PropTypes from 'prop-types';
import React from 'react';
import numeral from 'numeraljs';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import useSearchBar from 'components/Search/Bar/Provider';


const propTypes = {
  /**
   * Total number of items to display.
   */
  total: PropTypes.number.isRequired,
  /**
   * Starting index of items to display out of all possible items.
   */
  startIndex: PropTypes.number,
  /**
   * Number of items on current page.
   */
  numOnPage: PropTypes.number.isRequired,
  /**
   * Maximum number of items to display per page.
   */
  resultsPerPage: PropTypes.number,
  className: PropTypes.string,
};
/**
 * Renders pager for search results.
 */
export default function SearchPagination({total, startIndex = 0, numOnPage, resultsPerPage = 20, className}) {
  const searchBar = useSearchBar();

  const renderButton = (label, direction, offset, display) => {
    if (display) {
      return (
        <a href={searchBar.generateSearchUrl(searchBar.state, offset)}>
          <FontAwesomeIcon icon={['fas', `chevron-${direction}`]} />
          <span className="sr-only">{label} {resultsPerPage} results</span>
        </a>
      );
    }
    return (
      <span className="empty" aria-hidden={true}>
        <FontAwesomeIcon icon={['fas', `chevron-${direction}`]} />
      </span>
    );
  };

  const pageMin = startIndex + 1;
  const pageMax = Math.min(startIndex + numOnPage, total);
  const size = (total === 10000 ? 'over ' : '') + numeral(total).format('0,0');

  return (
    <nav className={clsx('searchPage__pager', className)} aria-label="pagination">
      {renderButton('Previous', 'left', startIndex - resultsPerPage, startIndex > 0)}
      <span className="searchPage__pager__status">Showing {pageMin}-{pageMax} of {size}</span>
      {renderButton('Next', 'right', pageMax, pageMax < total)}
    </nav>
  );
}
SearchPagination.propTypes = propTypes;
