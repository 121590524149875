import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import {isIgnorableKeydown} from 'components/Button';


const propTypes = {
  label: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  iconSize: PropTypes.string,
  children: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
};
/**
 * This extends Bootstrap's dropdown component to add a caret, giving it a speech bubble effect.
 */
export default function Dropdown({label = '', icon = '', iconSize = '1x', className, ariaLabel, children}) {
  const [isOpen, setOpen] = useState(false);

  const componentIsMounted = useRef(true);
  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const ref = useOnclickOutside(() => {
    if (componentIsMounted.current) {
      setOpen(false);
    }
  }, {
    ignoreClass: 'dropdown-menu',
  });

  const actionHandler = (event) => {
    if (isIgnorableKeydown(event)) {
      return;
    }
    if (componentIsMounted.current) {
      setOpen(!isOpen);
    }
  };

  return (
    <div className={clsx('nav-dropdown', {'nav-dropdown--show': isOpen}, className)}>
      <div
        ref={ref}
        className={clsx('nav-dropdown__anchor', {'nav-dropdown__anchor--show': isOpen})}
        role="button"
        tabIndex="0"
        aria-haspopup={true}
        aria-label={ariaLabel}
        onKeyPress={actionHandler}
        onClick={actionHandler}
      >
        {icon ? <span className="nav-dropdown__anchor__icon" aria-hidden={true}><FontAwesomeIcon icon={icon} aria-hidden="true" size={iconSize} /></span> : ''}
        <span className="nav-dropdown__anchor__label">{label}</span>
      </div>
      <div
        className={clsx('dropdown-menu', {show: isOpen})}
        role="menu"
        tabIndex="-1"
        onClick={actionHandler}
        onKeyPress={actionHandler}
      >
        <div className="drop-caret" role="presentation" tabIndex="-1" />
        {children}
      </div>
    </div>
  );
}
Dropdown.propTypes = propTypes;
