import {filter, includes, map} from 'lodash';

import literatureIcon from 'images/icons/literature.svg';

import InlinePage from 'components/Page/Inline';
import KyError from 'components/errors/KyError';
import Svg from 'components/shared/Svg';
import HtmlContainer from 'components/shared/html_container';
import FullWidthTemplate from 'components/templates/FullWidth';
import useAppContext from 'conf/AppContext';
import {useGet} from 'helpers/KyHooks';

export default function VipList({}) {
  const appContext = useAppContext();

  const {response, error} = useGet('site/vips', {
    cacheResponse: !appContext.isPreview,
  });
  if (error) {
    appContext.toastError(<KyError kyError={error} />);
  }
  const vips = response?.data;
  const tiers = ['Tier 1', 'Tier 2', 'Cancer Genome'];
  const totalVips = filter(vips, (v) => includes(tiers, v.tier))?.length;

  const makeVipItem = (vip) => {
    const {gene, litId, pmid, summary, vipId} = vip;
    const citationLink = !pmid ? null : (
      <div className="vipList-litLink">
        <Svg src={literatureIcon} /> <a href={`/literature/${litId}`}>PMID: {pmid}</a>
      </div>
    );
    const summarySection = summary ? <HtmlContainer html={summary} /> : null;
    return (
      <li key={gene} className="mb-4">
        <h5><a href={`/vip/${vipId}`}>{gene} PGx Summary</a></h5>
        <div className="ml-4">
          {summarySection}
          {citationLink}
        </div>
      </li>
    );
  };

  return (
    <FullWidthTemplate title="VIPs: Very Important Pharmacogenes">
      <InlinePage id="vipHeader" className="mb-4" />

      <section className="lead">
        <div>Total VIP annotations: {totalVips}</div>
        <ul className="list-unstyled">
          {map(tiers, (tier, i) => (
            <li key={tier}>
              <a href={`#tier${i}`}>{tier}</a>: {filter(vips, (vip) => vip.tier === tier)?.length}
            </li>
          ))}
        </ul>
      </section>

      <div>
        {map(tiers, (tier, i) => (
          <section key={tier} id={`tier${i}`}>
            <header><h2>{tier}</h2></header>
            <ol className="vip-list">
              {map(filter(vips, {tier}), makeVipItem)}
            </ol>
          </section>
        ))}
      </div>
    </FullWidthTemplate>
  );
}
