import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';

import './Delete.scss';
import WaitingButton from 'components/Button/Waiting';
import TrashIcon from 'components/icons/Trash';
import useModalService from 'components/shared/ModalService';
import useAppContext from 'conf/AppContext';


const propTypes = {
  /**
   * The URL to send DELETE request to.
   */
  url: PropTypes.string.isRequired,
  /**
   * The identifier to pass back to callback to identify what got deleted
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Function to call if button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Function to call on successful deletion (called with `onSuccess(id)`)
   */
  onSuccess: PropTypes.func.isRequired,
  /**
   * Function to call on failed deletion (called with `onFailure(id, error)`).
   */
  onFailure: PropTypes.func.isRequired,
  /**
   * The button's label.
   */
  label: PropTypes.string,
  /**
   * Determines if button is icon-only.
   * This will render a frame-less button.
   */
  iconOnly: PropTypes.bool,
  className: PropTypes.string,
  confirmMessage: PropTypes.string,
  /**
   * Disable the button
   */
  disabled: PropTypes.bool,
};
/**
 * Renders an button that will delete an object.
 *
 * @return {React.ReactNode} a single Button component
 */
export default function DeleteButton({url, id, onClick, onSuccess, onFailure, label = 'Delete',
  iconOnly = false, className, confirmMessage = 'Are you sure?', disabled = false}) {
  const appContext = useAppContext();
  const modalService = useModalService();
  const [clicked, setClicked] = useState(false);

  const componentIsMounted = useRef(true);
  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const clickHandler = async () => {
    if (clicked) {
      return;
    }
    setClicked(true);
    if (onClick) {
      onClick();
    }

    const yesHandler = async () => {
      try {
        await appContext.api.delete(url);
        if (componentIsMounted.current) {
          setClicked(false);
          onSuccess(id);
        }
      } catch (error) {
        if (componentIsMounted.current) {
          setClicked(false);
          onFailure(id, error);
        }
      }
    };
    const noHandler = () => {
      setClicked(false);
    };

    modalService.confirm({
      yesHandler,
      noHandler,
      content: confirmMessage,
    });
  };

  const icon = <TrashIcon />;
  const body = iconOnly ? icon : <span>{icon} {label}</span>;

  return (
    <WaitingButton
      actionHandler={clickHandler}
      className={clsx('button--delete', className)}
      iconOnly={iconOnly}
      title={iconOnly ? label : undefined}
      aria-label={label}
      waiting={clicked}
      disabled={disabled}
    >
      {body}
    </WaitingButton>
  );
}

DeleteButton.propTypes = propTypes;
