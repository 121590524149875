import {map} from 'lodash';
import PropTypes from 'prop-types';
import {useEffect} from 'react';

import ApproveAndDiffButtons from 'components/Button/ApproveAndDiff';
import EditButton from 'components/Button/Edit';
import HistoryTable from 'components/History/Table';
import LiteratureCitation from 'components/Literature/Citation';
import VipTierTag from 'components/Tag/VipTier';
import EditControls from 'components/edit/EditControls';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import HTMLContainer from 'components/shared/html_container';
import TableStylizer from 'components/shared/tableStylizer';
import {useTour} from 'components/tour/hook';
import {historyProps, literatureProps, markdownProps} from 'conf/types';


const propTypes = {
  vip: PropTypes.shape({
    attribution: PropTypes.string,
    id: PropTypes.string,
    citation: literatureProps,
    sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    })),
    summary: markdownProps,
    history: PropTypes.arrayOf(historyProps),
    tier: PropTypes.string,
  }),
  approved: PropTypes.bool,
  published: PropTypes.bool,
};

export default function VipOverviewTab({vip, approved, published}) {

  const readyForTour = useTour('vip');
  useEffect(() => {
    readyForTour();
  }, [readyForTour]);
  

  return (
    <div id="tour-vip-main-text">
      <EditControls>
        <EditButton href={`/edit/vip/${vip.id}`} label="Edit" />
        <ApproveAndDiffButtons objCls="VipGene" objId={vip.id} approved={approved} published={published} iconOnly={false} />
      </EditControls>

      <div className="mb-3">
        <VipTierTag tier={vip.tier} />
      </div>

      {vip?.summary?.html && <FactSection title="Summary"><HTMLContainer html={vip.summary.html} /></FactSection>}

      <FactSection sectionClasses="vip__sections" title="Annotation">
        {map(vip.sections, renderSection)}
      </FactSection>

      <FactSection>
        <Fact label="PharmGKB ID" literal={vip.id} />
      </FactSection>

      {vip?.citation?.id
        ? (
          <FactSection sectionClasses="vip__citation" title="Citation" id="vip-citation">
            <LiteratureCitation {...vip.citation} />
          </FactSection>
        )
        : ''}

      <FactSection sectionClasses="vip__attribution" title="Attribution">
        {vip.attribution}
      </FactSection>

      <FactSection title="History">
        <HistoryTable history={vip.history} parentCls="VipGene" parentId={vip.id} />
      </FactSection>
    </div>
  );
}
VipOverviewTab.propTypes = propTypes;

function renderSection(section) {
  if (section?.markdown?.html) {
    return (
      <FactSection key={section.id}>
        <TableStylizer isInSidebarLayout={true} html={section.markdown.html} />
      </FactSection>
    );
  }
  return null;
}
